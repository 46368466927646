import React, { useState } from 'react';
import { Alert } from 'react-native'
import { Container, TextInput, Unit, InputContainer } from './styles';
import Button from '../../../components/Button';
import checkin from '../../../api/checkin';
import Spinner from 'react-native-loading-spinner-overlay';

export default function ModalNumber(props){

    const [input, setInput] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    
    async function submit(){
        if(input !== ''){
            setLoading(true);
            const response = await checkin.submit(props.metricCollectorId, { value: parseInt(input), unit: props.unit })
            console.log('submit response TYPE NUMBER', response.status);
            if(response.status === 200){
                setLoading(false);
                props.return();
                if(props.toast){
                    props.toast('success', 'Sucess!');
                }
            }
            else{
                setLoading(false);
                props.return();
                if(props.toast){
                    props.toast('error', 'Sorry! Some error occured.');
                }
            }
        }
        else{
            Alert.alert('Please, fill the answer field!')
        }
    }

    return(
        <Container>
            <InputContainer>
                <TextInput placeholder='Answer' onChangeText={(text) => setInput(text)} keyboardType='number-pad' />
                <Unit>{props.unit}</Unit>
            </InputContainer>
            <Button onPress={submit} text='SUBMIT'/>
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                overlayColor='rgba(0, 83, 159,0.9)'
                color='#fff'
                textStyle={{color: '#fff'}}
            /> 
        </Container>
    )
}