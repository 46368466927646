import styled from 'styled-components/native';
import { Animated } from 'react-native';

export const Container = styled.View`
    flex: 1;
`
export const Title = styled(Animated.Text)`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
`
export const Subtitle = styled(Animated.Text)`
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
`
export const FeedbackText = styled(Animated.Text)`
    align-self: center;
    text-align: justify;
    width: 80%;
    font-size: 12px;
    line-height: 18px;
`
export const ButtonGroup = styled(Animated.View)`
    height: 100px;
    width: 100%;
    background-color: #e5e5e5;
    display: flex;
    flex-direction: row;
`