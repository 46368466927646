import { StyleSheet } from 'react-native';
import Colors from '../../constants/colors';

export default StyleSheet.create({
  normalStyle: {
    height: 76,
    backgroundColor: 'white',
  },
  selectedStyle: {
    backgroundColor: Colors.projectBlue,
  },
  normalTextStyle: {
    color: 'black',
    textAlign: 'center',
  },
  selectedTextStyle: {
    color: 'white',
    textAlign: 'center',
  }
});