import styled from 'styled-components/native';
import { ScrollView } from 'react-native';

export const Container = styled.View`
    flex: 1;
`
export const DateNow = styled.Text`
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    text-align: center;
`
export const Title = styled.Text`
    font-size: 20px;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    letter-spacing: 1px;
    margin-bottom: 30px;
`

export const Medication = styled.Text`
    font-size: 18px;
    text-align: center;
`

export const Unit = styled.Text`
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
`

export const ModalView = styled(ScrollView)`
    width: 90%;
`

export const InputGroup = styled.View`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-vertical: 30px;
`