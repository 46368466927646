import Colors from '../../constants/colors';
import styled from 'styled-components/native';

export const Container = styled.View`
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-top: 0px;
    flex: 1;
    justify-content: space-between;
    padding: 10px 10px 0 10px;
    
    ${ props => (props.focused) && `
        background-color: ${Colors.primaryColor}
    `};
`;

// TODO: find a better solution for min-width - removed temporarily

export const Label = styled.Text`
    color: ${Colors.tabIconDefault};
    font-size: 11px;
    padding-bottom: 4px;
    
    ${ props => (props.focused) && `
        color: white;
    `};
`;

// backgroundColor: 'red', paddingVertical: 15, paddingHorizontal: 20, paddingTop: 5