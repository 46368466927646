import React, { useEffect, useRef } from 'react';
import { View, Animated } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

import TextStyled from '../../../components/TextStyled';
import Colors from '../../../constants/colors';
import { InputContainer } from './styles';

export default function EndScreen(){
  const fadeAnim = React.useRef(new Animated.Value(0)).current;
  const animatedValue = useRef(new Animated.Value(0.5)).current;
  const animatedStyle = {
    transform: [{ scale: animatedValue }]
  }

  useEffect(() => {
    // Animated.timing(fadeAnim, {
    //   toValue: 1,
    //   useNativeDriver:true,
    //   duration: 1000
    // }).start();
    Animated.spring(animatedValue, {
      toValue: 1,
      friction: 3,
      tension: 20
    }).start();
  }, []);

  return(
    <InputContainer>
      <Animated.View style={[animatedStyle, {opacity: fadeAnim}]}>
        <AntDesign name={'checkcircleo'} size={200} color={Colors.projectBlue}/>
      </Animated.View>
      <TextStyled question text={`Thank you for telling us how you are doing. It will help us take better care of you and your concerns.`} style={{ marginTop: 20}}/>
      <TextStyled question text={`Please push the submit button below to finish your report.`} />
     </InputContainer> 
)}