import * as React from 'react';
import { Container, Title, Question, Response } from './styles';

export default function InteractionQuestion(props){
    return(
        <Container>
            <Title>{props.title}</Title>
            <Question>{props.question}</Question>
            <Response>{props.response}</Response>
        </Container>
    )
}