import styled from 'styled-components/native';

export const Container = styled.View`
    flex: 1;
    align-items: center;
`

export const ButtonGroup = styled.View`
    flex-direction: row;
    width: 50%;
    justify-content: center;
    margin-bottom: 20px;
`

export const Timer = styled.Text`
    text-align: center;
    font-size: 24px;
    color: #000;
    margin-bottom: 20px;
`

export const Cancel = styled.TouchableOpacity`
    height: 50px;
    width: 50px;
    ${props => props.disabled ? 
        `background-color: #ff5972` 
        : 
        `background-color: #AC162C`}
    border-radius: 50px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
`

export const Play = styled.TouchableOpacity`
    height: 50px;
    width: 50px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
`