import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';

export const Background = styled(LinearGradient)`
    flex: 1;
`
export const Icon = styled.Image`
    height: 200px;
    width: 200px
    resize-mode: contain;
    align-self: center;
    margin-top: 50px;
    margin-bottom: 50px;
`