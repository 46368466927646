import styled from 'styled-components/native';
import Colors from '../../constants/colors';
import { RFValue } from 'react-native-responsive-fontsize';

export const Container = styled.View`
    flex: 1;
    align-items: center;
`

export const Title = styled.Text`
    font-size: 24px;
`

export const WeekArea = styled.View`
    margin-top: 10px;
    margin-bottom: 20px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    border-width: 1px;
    border-color: #ddd;
    align-items: center;
    justify-content: center;
`

export const WeekText = styled.Text`
    padding: 10px;
    width: 30%;
    font-size: ${RFValue(11)}px;
    color: ${Colors.primaryColor};
    border-left-width: 1px;
    border-right-width: 1px;
    border-color: #ddd;
    text-align: center;
`

export const WeekButton = styled.Text`
    padding: 10px;
    color: ${Colors.primaryColor};
    font-size: ${RFValue(11)}px;
    font-weight: bold;
`

export const Subtitles = styled.View`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
`

export const SubtitleContainer = styled.View`
    padding: 5px;
    border-width: 1px;
    border-color: #aaa;
    border-radius: 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-horizontal: 10px;
`

export const SubtitleColor = styled.View`
    height: 20px;
    width: 20px;
    border-radius: 20px;
    margin-right: 5px;
    ${props => `background-color: ${props.color}`}
`

export const SubtitleText = styled.Text`
    font-size: 10px;
    text-align: center;
`

export const TurnScreen = styled.View`
    flex: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 83, 159,0.9);
    align-items: center;
    justify-content: center;
`

export const TurnScreenText = styled.Text`
    font-size: 20px;
    color: #fff;
    width: 70%;
    text-align: center;
    margin-top: 30px;
`

export const Close = styled.TouchableOpacity`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
`