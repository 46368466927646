import styled from 'styled-components/native';
import Colors from '../../constants/colors';
import { StyleSheet, Animated } from 'react-native';


export const Container = styled(Animated.View)`
    display: flex;
    width: 100%;
    align-self: center;
    align-items: center;
    height: 90%;
    justify-content: center;
`

export const Item = styled.View`
    align-items: center;
    width: 80%;
    height: 80%;
`
export const ContentImage = styled.Image`
    height: 80px;
    width: 75px;
    margin-bottom: 20px;
    margin-top: 15px;
    resize-mode: contain;
`

export const Title = styled.Text`
    color: ${Colors.primaryColor};
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: bold;
    margin-bottom: 20px;
`

export const Subtitle = styled.Text`
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
`

export const styles = StyleSheet.create({
    middleText: {
        paddingBottom: 20,
        textAlign: 'center',
        lineHeight: 20
    },
})