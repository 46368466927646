import * as React from 'react';
import { ImageBackground, StyleSheet, Text, View,Platform,Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Typography } from '../../styles';
import Colors from '../../constants/colors';

export default function Card(props) {
 let screenSize= Dimensions.get('screen').height
  return (
    <ImageBackground source={props.image} style={[styles.container,styles[props.size]]}>
        <Text style={[styles.title,{
          paddingBottom: Platform.OS ==='web'?props.size=='small'? screenSize*0.06:screenSize*0.11:0
          }]}>
            {props.title}
        </Text>
        <View style={styles.footer}>
            <Text style={[styles.status]}>{ props.statusText }</Text>
            <View style={styles.seeMore}>
                <Text style={[styles.buttonLabel, Typography.uppercase]}>{ props.buttonLabel }</Text>
                <Text style={styles.icon}><Ionicons name="chevron-forward-circle-outline" size={18} /></Text>
            </View> 
        </View>
        {props.notification &&
          <View style={styles.notification}>
            <Text style={[styles.notificationText, Typography.medium]}>{props.notification}</Text>
          </View>
        }
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'space-between',
      flexDirection: 'column',
      borderRadius: 5,
      padding: 10,
      marginBottom: 14,
      overflow: 'hidden',
      position: 'relative',
    },
    small: {
      height: 85
    },
    medium: {
      height: 127
    },
    large: {
      height: 145
    },
    title: {
      color: 'white',
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 23,
      // paddingBottom: Platform.OS ==='web'? '12%':0
      // height:110
    },
    notification: {
      backgroundColor: 'white',
      borderBottomLeftRadius: 5,
      position: 'absolute',
      right: 0,
      top: 0,
      paddingHorizontal: 10,
      paddingVertical: 5
    },
    notificationText: {
      color: Colors.primaryColor,
      fontSize: 12
    },
    footer: {
      backgroundColor: 'rgba(220, 220, 220, 0.88)',
      borderStyle: 'solid',
      borderTopColor: 'rgb(235, 235, 235)',
      borderTopWidth: 2,
      margin: -20,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      paddingVertical: 4,
      paddingLeft: 25,
      paddingRight: 15,
      paddingBottom: 14,
    },
    status: {
      color: 'black',
      fontSize: 12,
      letterSpacing: 0
  
    },
    seeMore: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    buttonLabel: {
      fontSize: 12,
      letterSpacing: 0.75
    },
    icon: {
      color: Colors.primaryColor,
      marginLeft: 5
    }
});  