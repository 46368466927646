import * as React from 'react';
import { ImageBackground,StyleSheet, Text, View ,TouchableOpacity} from 'react-native';
import { Typography } from '../../styles';
import { Ionicons, MaterialCommunityIcons, FontAwesome, Feather } from '@expo/vector-icons';
import Colors from '../../constants/colors';

export default function CircledButton(props) {
  return (
    <TouchableOpacity onPress={props.Action}>
      <View onPress style={[styles.circle, { backgroundColor: props.background }, { width: props.size }, { height: props.size }, (props.hasBorder) ? styles.border : null ]} > 
          { props.icon }
          <Text style={[ styles.textCircle, { color:props.textColor }, { fontSize:props.textSize }]}>{ props.title }</Text>
          { props.completed ? (
            <MaterialCommunityIcons name="check-circle-outline" color="#0053A1" size={20} />
          ) : (
            <Text style={styles.textCircleNumbers}>{ props.statusText }</Text>
          ) }
      </View>
    </TouchableOpacity>

  );
}
const styles = StyleSheet.create({
    border: {
      borderColor: '#000',
      borderWidth: 1,
      borderStyle: 'solid',
    },
    circle: {
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 100,
      margin: 10,
      marginBottom: 20,
      paddingTop: 10,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.20,
      shadowRadius: 7.46,
      elevation: 9,
    },
    textCircle: {
      color:'#FFF',
      textAlign: 'center',
      paddingHorizontal: 5,
      paddingTop: 5,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      // fontFamily: 'SFProDisplayBold',
    },
    textCircleNumbers: {
      color:'#FFFFFF',
      fontSize: 13,
    },
});  