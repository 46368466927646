import * as React from 'react';
import { Alert } from 'react-native';
import Spinner from 'react-native-loading-spinner-overlay';
import { Container, Label, Spacing } from './styles';

import { TextInputMask } from 'react-native-masked-text';
import Button from '../../../components/Button';
import auth from '../../../api/authentication';

export default function ForgotPincode(props){

    const [telephone, setTelephone] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    async function resetPincode(){
        if(telephone !== ''){
            setLoading(true);
            const response = await auth.requestPinReset(telephone);
            console.log('response: ', response)
            if(response.success){
                setLoading(false);
                props.return();
                if(props.toast){
                    props.toast('success', 'Success!');
                }
            }
            else{
                setLoading(false);
                props.return();
                if(props.toast){
                    props.toast('error', 'Sorry! Some error occured.');
                }
            }
        }
    }

    return(
        <Container>
            <Label>Enter your phone number below:</Label>
            <Spacing />
            <TextInputMask 
                value={telephone} 
                onChangeText={(text) => {
                    setTelephone(text)
                }}
                type={'custom'}
                options={{mask: '999-999-9999', }}
                placeholder='Telephone Number'
                style={{
                    borderBottomWidth: 1,
                    borderBottomColor: '#000', 
                    fontSize: 14, 
                    paddingVertical: 2,
                    width: '80%',
                }}
            />
            <Spacing />
            <Button text='SEND PINCODE RESET REQUEST' onPress={resetPincode}/>
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                overlayColor='rgba(0, 83, 159,0.9)'
                color='#fff'
                textStyle={{color: '#fff'}}
            />
        </Container>
    )
}