import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';;;;
import Colors from '../../constants/colors';

import { Circle, ProgressBarBase, ProgressBarProgress} from './styles';

export default function ProgressBar(props) {
  return(
    <>
      <ProgressBarBase {...props} >
        <ProgressBarProgress 
          {...props} 
          colors={[Colors.projectBlue, Colors.crimson]} 
          start={[0, 0.5]} 
          end={[1, 0.5]}
        />
      </ProgressBarBase>    
    </>
  );
}
ProgressBar.propTypes = {
  progress: PropTypes.string.isRequired,
}



