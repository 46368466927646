import * as React from 'react';
import { View, TouchableOpacity, Linking } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import Button from '../../components/Button';
import CustomListItem from '../../components/CustomListItem';
import Colors from '../../constants/colors';
import Header from '../../components/Header';
import { Container, Information, ButtonGroup, Warning, InformationText, InformationImage, ContactTitle, Bold } from './styles';
import careteam from '../../api/care-team.js';
import Popup from '../../components/Modal';
import ModalRequestCallback from './ModalRequestCallback';
import ModalRequestAppointment from './ModalRequestAppointment';

export default function ProviderDetail({ route }){
    
    const navigation = useNavigation();
    
    // Provider data from previous page (id, name and role)
    const { Provider } = route.params;

    // provider data from API
    const [providerData, setProviderData] = React.useState({});
    const [modalCallbackVisible, setModalCallbackVisible] = React.useState(false);
    const [modalAppointmentVisible, setModalAppointmentVisible] = React.useState(false);

    React.useEffect(() => {
        async function getData(){
            setProviderData(await careteam.getProviders(Provider));
        }
        getData();
    },[]);

    async function requestAppointment(){
        setModalAppointmentVisible(true);
    }

    async function requestCallback(){
        setModalCallbackVisible(true);
    }
    
    function callPractice(){
        Linking.openURL(`tel:${providerData.contactPhoneNumber}`);
    }
    
    function sendEmail(){
        Linking.openURL(`mailto:${providerData.contactEmail}`);
    }
    
    function back(){
        return(
            <TouchableOpacity onPress={() => navigation.goBack()}>
                <AntDesign name='leftcircleo' size={40} color={Colors.primaryColor}/>
            </TouchableOpacity>
        );
    }

    function toggleModalCallback(){
        setModalCallbackVisible(!modalCallbackVisible);
    }
    function toggleModalAppointment(){
        setModalAppointmentVisible(!modalAppointmentVisible);
    }
    
    return(
        <Container>
            <Header solidBar leftButton={back}/>
            <Information>
            <InformationText>{Provider.name}, {Provider.role}</InformationText>
                <InformationImage source={{uri: providerData.profileImageUrl}}/>
            </Information>
            <ButtonGroup>
                <Button width={150} text={`REQUEST\nAPPOINTMENT`} color={Colors.primaryColor} textColor={Colors.noticeText} onPress={requestAppointment}></Button>
                <Button width={150} text={`REQUEST\nCALLBACK`} color={Colors.noticeText} textColor={Colors.noticeText} onPress={requestCallback}></Button>
            </ButtonGroup>
            { 
                (providerData.contactPhoneNumber !== '' || providerData.contactEmail !== '') && 
                <ContactTitle>How to Contact: <Bold>{Provider.name}</Bold></ContactTitle>
            }
            { providerData.contactPhoneNumber !== '' && <CustomListItem
                title={`\tCall Practice`}
                rightIcon={<AntDesign name='rightcircleo' size={25} color={Colors.primaryColor} />}
                bottomDivider
                onPress={callPractice}
            />}
            { providerData.contactEmail !== '' && <CustomListItem
                title={`\tSend Email`}
                rightIcon={<AntDesign name='rightcircleo' size={25} color={Colors.primaryColor} />}
                bottomDivider
                onPress={sendEmail}
            />}
            <Warning>If You Have A Medical Emergency CALL 911 Immediately</Warning>

            <Popup  
                titleModal="Request Callback?"
                contentModal={<ModalRequestCallback name={Provider.name} providerId={Provider.id} return={toggleModalCallback}/>}
                onClose={() => toggleModalCallback()} 
                stateModal={modalCallbackVisible} />
            <Popup  
                  titleModal="Request Appointment?"
                  contentModal={<ModalRequestAppointment name={Provider.name} providerId={Provider.id} return={toggleModalAppointment}/>}
                  onClose={() => toggleModalAppointment()} 
                  stateModal={modalAppointmentVisible} />
        </Container>
    )
}