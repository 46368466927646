import { AsyncStorage } from 'react-native';

export async function withHeaders() {
  return {
    headers: {
      'Content-Type': 'application/json',
    }
  };
}

// get Authorization string in format 'Bearer ey6512...'
async function getAuth(){
  try{
      const token = await AsyncStorage.getItem('TOKEN');
      const auth =`Bearer ${token}`;
      return auth;
  }
  catch(e){
      console.log('Error getting TOKEN', e);
  }
  return null;
}

export default {
  withHeaders,
  getAuth,
};