import * as React from 'react';
import { TouchableOpacity, ScrollView, View, Alert, TextInput } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

import { Container, DateNow, Title, Medication, Unit, ModalView, InputGroup } from './styles';
import Header from '../../components/Header';
import Colors from '../../constants/colors';
import CustomListItem from '../../components/CustomListItem';
import Popup from '../../components/Modal';
import med from '../../api/medications';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Spinner from 'react-native-loading-spinner-overlay';
import Toaster from 'react-native-root-toast';

export default function AddMedications({ route }){
    
    const navigation = useNavigation();
    const { metricCollectorId } = route.params;
    const [toast, setToast] = React.useState(null);

    const date = new Date();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    // all medications from API
    const [medications, setMedications] = React.useState([]);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    
    // medication clicked to Add (used on modal)
    const [modalMed, setModalMed] = React.useState({});

    React.useEffect(() => {
        async function getData(){
            setMedications(await med.getMedications());
        }
        getData();
    }, [])
    
    function back(){
        return(
            <TouchableOpacity onPress={() => navigation.goBack()}>
                <AntDesign name='leftcircleo' size={40} color={Colors.primaryColor} />
            </TouchableOpacity>
        );
    }

    function toggleModal(){
        setModalVisible(!modalVisible);
    }

    function action(m){
        setModalMed(m);
        toggleModal();
    }

    async function confirmAddMedication(){
        setLoading(true);
        console.log(modalMed.dosage)
        const data = {metricCollectorId: metricCollectorId, name: modalMed.name, dosage: modalMed.dosage};
        const response = await med.submitMedication(data);
        if(response.success){
            showToast('success', 'Sucess!');
        }
        else{
            showToast('error', 'Sorry! Some error occured.');
        }
        setLoading(false);
        setModalVisible(false);
    }

    function showToast(type, msg){
        setToast({text: msg, styles: 
            {
                container: {
                    marginTop: 50, 
                    backgroundColor: type === 'success' ? '#1ddca9' : '#ff4073',
                    width: 250,
                    padding: 10,
                    borderRadius: 100,
                    alignSelf: 'center',
                },
                text: {
                    color: '#fff',
                    textAlign: 'center',
                    fontWeight: 'bold',
                }
        }, duration: 1000}
        );
        setToast(null)
    }

    return(
        <Container>
            <Header solidBar leftButton={back}/>
            <DateNow>{`${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`}</DateNow>
            <Title>Add Medications</Title>
            <ScrollView keyboardShouldPersistTaps='handled'>
                {
                    medications.map((m, i) => (
                        <CustomListItem
                            key={i}
                            title={`${m.name} - ${m.dosage}`}
                            rightIcon={<AntDesign name='plus' size={25} color={Colors.primaryColor}/>}
                            onPress={() => action(m)}
                        />
                    ))
                }
                {modalVisible&&
                    <Popup  
                    titleModal="Confirm Add Medication"
                    contentModal={
                        <ModalView keyboardShouldPersistTaps='handled'>
                            <Medication>Name: {modalMed.name}</Medication>
                            <Unit>Dosage: {modalMed.dosage}</Unit>
                            <Button text='CONFIRM' onPress={confirmAddMedication} />
                            <Spinner
                                visible={loading}
                                textContent={'Loading...'}
                                overlayColor='rgba(0, 83, 159,0.9)'
                                color='#fff'
                                textStyle={{color: '#fff'}}
                                /> 
                        </ModalView>
                    }
                    onClose={() => toggleModal()} 
                    stateModal={modalVisible} />
                }
            </ScrollView>
            {toast&&
            <Toaster>{Toaster.show(toast,{
              duration: Toaster.durations.LONG,
              animation: true,
              hideOnPress: true,
            })}</Toaster>
          }
        </Container>
    )
}