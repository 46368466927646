import * as React from 'react';
import { ListItem } from 'react-native-elements';
import { Text } from 'react-native';
import styles, { Bullet } from './styles';

export default function CustomListItem(props){
    return(
        <ListItem
            key={props.i}
            title={props.title}
            rightIcon={props.rightIcon}
            containerStyle={styles.container}
            titleStyle={props.new ? styles.titleNew : styles.titleRead}
            subtitle={props.subtitle || null}
            subtitleStyle={styles.subtitle}
            bottomDivider={props.bottomDivider}
            onPress={props.onPress}
            leftElement={props.new ? <Bullet /> : null }
            subtitleStyle={{color: '#000', fontSize: 11}}
        />
    )
}