import React, { useState } from 'react';
import { AntDesign } from '@expo/vector-icons';
import { ListItem } from 'react-native-elements';
import { TouchableOpacity } from 'react-native-gesture-handler';

import Colors from '../../constants/colors'
import { Circle } from './styles';

function multiOption(option, index, selectedOptions, setSelectedOptions){
  const [selected, setSelected] = useState(!option.value);

  function handleSelect(index) {
    setSelected(!selected);
    let newSelectedOptions = selectedOptions;
    if(selected === false){
      newSelectedOptions.push(index);
      setSelectedOptions(newSelectedOptions);
    }
    else{
      newSelectedOptions = newSelectedOptions.filter((i) => {
        return i !== index;
      })
    }
    setSelectedOptions(newSelectedOptions);
  };

  function iconSelection() {
    if (selected) { 
      return <AntDesign name={'checkcircle'} size={35} color={Colors.projectBlue}/> 
    } else {
      return <Circle />
    }
  }


  return(
    <TouchableOpacity onPress={() => handleSelect(index)} key={index}>
      <ListItem
        title={option.label}
        containerStyle={{ height: 65 }}
        titleStyle={{ fontSize: 20, textAlign: 'left' }}
        bottomDivider
        rightIcon={iconSelection}
      />
    </TouchableOpacity>
  )
}

export default function MultipleOption(props){
  const options = props.options;
  const [selectedOptions, setSelectedOptions] = useState([]);
  props.response(JSON.stringify(selectedOptions.map( i => i+1 )));

  return(
    options.map((option, index) => (
      multiOption(option, index, selectedOptions, setSelectedOptions)
    ))
  )
}