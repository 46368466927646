import * as React from 'react';
import { Container, Label, Bold, Warning, Spacing } from './style';
import Button from '../../../components/Button';
import careteam from '../../../api/care-team';
import Popup from '../../../components/Modal';

export default function ModalRequestCallback(props){

    const [successVisible, setSuccessVisible] = React.useState(false);

    async function confirm(){
        await careteam.requestCallback(props.providerId);
        setSuccessVisible(true);
    }

    function modalReturn(){
        setSuccessVisible(false); 
        props.return();
    }

    return (
        <Container>
            <Label>Confirm below to request a callback from: <Bold>{props.name}</Bold></Label>
            <Spacing />
            <Button text='CONFIRM' onPress={confirm}/>
            <Spacing />
            <Warning>If You Have A Medical Emergency CALL 911 Immediately</Warning>
            <Popup  
                titleModal="Success"
                contentModal={
                    <Container>
                        <Label>Callback Request Sent.</Label>
                        <Spacing />
                        <Button text='THANK YOU' onPress={modalReturn}/>
                        <Spacing />
                        <Warning>If You Have A Medical Emergency CALL 911 Immediately</Warning>
                    </Container>
                }
                onClose={modalReturn} 
                stateModal={successVisible} />
        </Container>
    )
}