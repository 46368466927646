import styled from 'styled-components/native';
import Colors from '../../constants/colors';

export const InfoGroup = styled.View`
    margin-left: 30px;
    display: flex;
    justify-content: space-around;
`
export const Title = styled.Text`
    font-weight: bold;
    font-size: 16px;
`
export const Subtitle = styled.Text`
    font-size: 15px;
`
export const ContactTitle = styled.Text`
    border-bottom-color: ${Colors.tabIconDefault};
    border-bottom-width: 1px;
    border-top-color: ${Colors.tabIconDefault};
    border-top-width: 1px;
    margin-top: 30px;
    padding-vertical: 15px;
    font-size: 18px;
    padding-left: 20px;
`
export const Warning = styled.Text`
    font-size: 14px;
    font-weight: bold;
    color: #c91e31;
    width: 60%;
    text-align: center;
    align-self: center;
    margin-top: 50px;
`
export const Bold = styled.Text`
    font-weight: bold;
`