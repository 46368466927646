import * as React from 'react';
import PropTypes from 'prop-types';

import { ButtonContainer, Label } from './styles';

export default function Button(props){
    return(
        <ButtonContainer onPress={props.onPress} {...props}>
            <Label outline={props.outline}>{props.text}</Label>
        </ButtonContainer>
    )
}

ButtonContainer.propTypes = {
    text: PropTypes.string.isRequired,
    outline: PropTypes.any
}
