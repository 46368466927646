import styled from 'styled-components/native';
import Colors from '../../constants/colors';
import { RFValue } from 'react-native-responsive-fontsize';

const ButtonContainer = styled.TouchableOpacity`
  height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 40px;
  width: ${ props=> props.width ? props.width : '90%'};
  align-items: center;
  align-self: center;
  justify-content: center;
  ${props => props.outline ? 
    `border-width: 2px;
    border-color: ${Colors.primaryColor};`
  :
  `background-color: ${Colors.primaryColor}`
  }
`;

const Label = styled.Text`
  text-align: center;
  align-self: center;
  font-size: ${RFValue(11)}px;
  font-weight: bold,
  text-transform: uppercase;
  ${props => props.outline ?
    `color: ${Colors.primaryColor};`
    :
    `color: ${Colors.noticeText};`
  }
`;
export { Label, ButtonContainer };