import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Colors from '../../constants/colors';

export const Background = styled(LinearGradient)`
    flex: 1;
`
export const Logo = styled.Image`
    height: 80px;
    width: 80px;
    resize-mode: contain;
    margin-top: 30px;
    align-self: center
`
export const Title = styled.Text`
    font-size: 22px;
    align-self: center;
    color: ${Colors.noticeText};
    margin-top: 10px;
    margin-bottom: 20px;
    letter-spacing: 1px;
`
export const TabItem = styled.View`
    flex: 1;
    background-color: #F2F4F6;
`

export default StyleSheet.create({
    tabBar: {
        backgroundColor: 'transparent', 
        height: 45
    },
    labelStyle: {
        fontSize: 16, 
        fontWeight: 'bold'
    },
    indicatorStyle: {
        backgroundColor: '#F2F4F6', 
        height: '100%', 
        borderTopRightRadius: 15, 
        borderTopLeftRadius: 15
    },
})