import utils from './utils';
import api from '../constants/api';
import axios from 'axios';

// get the provider data from API
export async function getProviders(Provider){
    const reqURL = `${api.baseUrl}/v1/care-team/providers/${Provider.id}`;
    const response = await axios.get(reqURL, {headers: {Authorization: await utils.getAuth()}});
    return response.data;
}

// get the caregiver data from API
export async function getCaregivers(Caregiver){
    const reqURL = `${api.baseUrl}/v1/care-team/caregivers/${Caregiver.id}`;
    const response = await axios.get(reqURL, {headers: {Authorization: await utils.getAuth()}});
    return response.data;
}

// get all providers from API
export async function getAllProviders(){
    try{
        const reqURL = `${api.baseUrl}/v1/care-team/providers`;
        const response = await axios.get(reqURL, {headers: {Authorization: await utils.getAuth()}});
        return response.data;
    }
    catch(e){
        console.log('Error getting all providers', e);
    }
}

// get all caregivers from API
export async function getAllCaregivers(){
    const reqURL = `${api.baseUrl}/v1/care-team/caregivers`;
    const response = await axios.get(reqURL, {headers: {Authorization: await utils.getAuth()}});
    return response.data;
}

export async function requestAppointment(id){
    const response = await axios.post(`${api.baseUrl}/v1/care-team/providers/${id}/request-appointment`, {providerId: id}, {headers: {Authorization: await utils.getAuth()}});
    console.log('Appointment requested!', response.status);
    return response.data;
}

export async function requestCallback(id){
    const response = await axios.post(`${api.baseUrl}/v1/care-team/providers/${id}/request-callback`, {providerId: id}, {headers: {Authorization: await utils.getAuth()}});
    console.log('Callback Requested!', response.status);
    return response.data;
}

export default {
    getProviders,
    getCaregivers,
    getAllCaregivers,
    getAllProviders,
    requestAppointment,
    requestCallback,
}