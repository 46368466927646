import api from '../constants/api';
import utils from './utils';
import axios from 'axios';

async function getDashboard(){
    try{
        const response = await axios.get(`${api.baseUrl}/v1/dashboard`, {headers: {Authorization: await utils.getAuth()}});
        return response.data;
    }
    catch(e){
        console.log('Error getting dashboard items: ', e);
        return false;
    }
}

export default {
    getDashboard,
}