import utils from './utils';
import api from '../constants/api';
import axios from 'axios';
import * as FileSystem from 'expo-file-system';

async function submitPhotos(images, metricCollectorId, callback){
    var paths = [];
    images.forEach(async (img, i) => {
        let filename = img.split('/').pop();
        let base64 = await FileSystem.readAsStringAsync(img, {encoding: FileSystem.EncodingType.Base64});
        console.log('img 64:', base64);

        await axios.post(`${api.baseUrl}/v1/checkin/upload`, {fileName: filename, fileDataBase64: base64}, {headers: {Authorization: await utils.getAuth()}})
        .then(async response => {
            paths.push(response.data);
            if(paths.length === images.length){
                await axios.post(`${api.baseUrl}/v1/checkin/submit`, { metricCollectorId: metricCollectorId, value: {imageFilePaths: paths} }, {headers: {Authorization: await utils.getAuth()}})
                .then(response => {
                    if(callback){
                        callback({success: true, response});
                    }
                    return {success: true, response};
                })
                .catch(e => console.log(e));
            }
        })
        .catch(e => console.log(e));
    });
    if(images.length === 0){
        callback({success: false});
    }
}

async function submitAudio(audio, metricCollectorId, callback){
    try{
        console.log('file path ', audio)
        let fileName = audio.split('/').pop();
        fileName = fileName.split('.')[0] + '.mp3';
        console.log('filename: ', fileName);
        var file = await FileSystem.readAsStringAsync(audio, {encoding: FileSystem.EncodingType.Base64});
        // console.log(file);

        const fileResponse = await axios.post(`${api.baseUrl}/v1/checkin/upload`, {fileName: fileName, fileDataBase64: file}, {headers: {Authorization: await utils.getAuth()}});
        console.log('api response file upload', fileResponse.data);

        // submit the filepath
        console.log({ metricCollectorId: metricCollectorId, value: fileResponse.data });
        const submitResponse = await axios.post(`${api.baseUrl}/v1/checkin/submit`, { metricCollectorId: metricCollectorId, value: fileResponse.data }, {headers: {Authorization: await utils.getAuth()}});
        return {success: true, response: submitResponse};

    }
    catch(e){
        console.log('Error uploading audio or filepath', e);
        return {success: false, e};
    }
}

export default {
    submitPhotos,
    submitAudio,
}