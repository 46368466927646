import styled from 'styled-components/native';

const ContentModal = styled.View`
  width:100%;
  background-color: #FFFFFF;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap:wrap;
  padding-top: 0px;
  padding-bottom: 25px;
`;

const TitleContent = styled.Text`
  color: #000;
  font-weight: normal;
  font-size: 13px;
  text-align: center;
`;
const ContainerInput = styled.View`
  flex-direction: row;
  align-content: center;
  position: relative;
  margin-top: 30px;
  margin-bottom:30px;
  padding-bottom: 25px;
`;
const InputBlood = styled.TextInput`
    color: #000;
    width:110px;
    font-weight: bold;
    font-size: 13px;
    height:45px;
    text-align: center;
    border-bottom-width: 1.5px;
    border-bottom-color: #000000;
`;
const Separator = styled.View`
    background-color: #000;
    position: relative;
    top:10px;
    margin-left:20px;
    margin-right:20px;
    height:40px;
    transform: rotate(30deg);
    width:3px;
`;

export {  
  ContentModal,
  TitleContent,
  ContainerInput,
  InputBlood,
  Separator };
