import styled from 'styled-components/native';

export const Container = styled.View`
    flex: 1;
    align-items: center;
`

export const InputContainer = styled.View`
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 20px;
`

export const Unit = styled.Text`
    font-size: 10px;
    text-align: center;
`

export const TextInput = styled.TextInput`
    height: 45px;
    color: #000;
    width: 100px;
    border-bottom-width: 1px;
    text-align: center;
`