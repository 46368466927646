import styled from 'styled-components/native';

const Container = styled.View`
  flex: 1;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const Datetime = styled.Text`
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
`;

const TitleHeader = styled.Text`
  color: rgb(0, 0, 0);
  font-weight: 100;
  font-size: 24px;
  text-align: center;
  border-bottom-width: 1px;
  border-bottom-color: #D8D8D8;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const Textarea = styled.TextInput`
  color: rgb(0, 0, 0);
  width: 90%;
  margin: auto;
  padding: 20px;
  text-align-vertical: top;
  background-color: #FFFFFF;
  min-height: 380px;
  margin-top: 15px;
  font-size:15px;
  box-shadow:  2px 2px 8px rgb(218, 218, 218);
`;

const TextBottom = styled.Text`
  color: #5A5A5A;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
`;

const TextHint = styled.Text`
  color: #7F7F7F;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
`;
const ContainerButton = styled.View`
  width:90%;
  margin:0 auto;
  align-items: center;
  text-align: center;
`;

const ImageList = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 50px;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  margin-top: 10px;
`

const ImageName = styled.Text`
  font-size: 12px;
  overflow: hidden;
  width: 70%;
`

export {  Container, 
          Datetime, 
          TitleHeader, 
          Textarea, 
          TextHint, 
          TextBottom,
          ContainerButton,
          ImageList,
          ImageName };
