import styled from 'styled-components/native';

export const Container = styled.View`
    flex: 1;
`
export const Title = styled.Text`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
`

export const Subtitle = styled.Text`
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
`