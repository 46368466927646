import styled from 'styled-components/native';

export const TextStyles = styled.Text`
  width: 90%;
  margin-bottom: 10px;
  text-align: center;
  align-self: center;
  color: black;

  ${ props => (props.title) && `
    font-size: 16px;
    font-weight: bold;
  `};

  ${ props => (props.question) && `
    font-size: 24px;
  `};

  ${ props => (props.message) && `
    font-size: 13px;
    color: #5A5A5A;
  `};

  ${ props => (props.warning) && `
    font-size: 18px;
    color: red;
  `};
`;