import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  track: {
    width: 250,
    height: 20,
    borderRadius: 20
  },
  thumb: {
    width: 30, 
    height: 30, 
    borderRadius: 30,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  }
})

export const InputContainer = styled.View`
  flex: 1;
  flex-direction: column;
  background-color: #F7F9FB;
  align-items: center;
`
export const InputText = styled.Text`
  margin: 10px 0;
  font-size: 13px;
  color: #5A5A5A;
`

export const BarInputContainer = styled.View`
  width: 400px;
  height: 100px;
  margin: 30px 0;
` 
export const BarAndNumbers = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const ScaleNumber = styled.Text`
  margin: 0 10px;
  font-size: 20px;
`
export const LeftText = styled.Text`
  margin: 0 20px;
  font-size: 16px;
  left: 10px;
  max-width: 40%;
  text-align: left;
`
export const RightText = styled.Text`
  margin: 0 20px;
  font-size: 16px;
  right: 10px;
  height: auto;
  max-width: 40%;
  text-align: right;
`

export const LabelsContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;