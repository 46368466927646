import React from 'react';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    input: {
        height: 40,
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
        borderBottomColor: '#000000',
        borderBottomWidth: 1,
        textAlignVertical: 'center',
    }
});

