import React, { useState } from "react";

import {  
  ContentModal,
  TitleContent,
  ContainerInput,
  InputBlood,
  Separator
} from './styles';

import Button from '../../../components/Button';
import checkin from '../../../api/checkin';
import { Alert } from "react-native";
import Spinner from 'react-native-loading-spinner-overlay';

export default function ModalBloodPressure(props){

  const [systolic, setSystolic] = React.useState(null);
  const [diastolic, setDiastolic] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  async function submitBloodPressure(){
    if (systolic !== null && diastolic !== null){
      setLoading(true);
      const response = await checkin.submit(props.metricCollectorId, { systolic: parseInt(systolic), diastolic: parseInt(diastolic) });
      // setLoading(false);
      if(response.status === 200){
        setLoading(false);
        props.return();
        if(props.toast){
          props.toast('success', 'Sucess!');
        }
      }
      else{
        setLoading(false);
        props.return();
        if(props.toast){
          props.toast('error', 'Sorry! Some error occured.');
        }
      }
    }
    else{
      Alert.alert(`Please fill in all fields!`);
    }
  }

  return(
      <ContentModal>
        <TitleContent> Please enter your resting blood pressure here </TitleContent>
        <ContainerInput>
          <InputBlood
            keyboardType='number-pad'
            placeholder='Field Label'
            onChangeText={(text) => setSystolic(text)}
          />
          
          <Separator />

          <InputBlood
            keyboardType='number-pad'
            placeholder='Field Label'
            onChangeText={(text) => setDiastolic(text)}
          />
        </ContainerInput>
        <Button onPress={submitBloodPressure} text="Submit Entry" color='#0053a1' textColor='#fff' /> 
        <Spinner
          visible={loading}
          textContent={'Loading...'}
          overlayColor='rgba(0, 83, 159,0.9)'
          color='#fff'
          textStyle={{color: '#fff'}}
        />    
     </ContentModal>
  )
}


