import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient';

export const ProgressBarBase = styled.View`
  height: 10px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
`;

export const ProgressBarProgress = styled(LinearGradient)`
  height: 10px;
  width: ${props => props.progress};
  border-radius: 5px;
`;

export const Circle = styled.TouchableOpacity`
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background-color: white;

`