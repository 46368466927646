import * as React from 'react';
import { View, Dimensions, Animated } from 'react-native';
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler';

import styles, { Background, Logo, Title, TabItem } from './styles';
import Colors from '../../constants/colors';
import CustomListItem from '../../components/CustomListItem';
import fb from '../../api/feedback';
// import SkeletonContent from 'react-native-skeleton-content';

// top tab styles
const renderTabBar = props => (
    <TabBar {...props} 
        style={styles.tabBar}
        activeColor={Colors.primaryColor}
        inactiveColor={Colors.noticeText}
        labelStyle={styles.labelStyle}
        indicatorStyle={styles.indicatorStyle}
    />
)

export default function FeedbackScreen(){
    
    const navigation = useNavigation();
    
    const [newItems, setNewItems] = React.useState([]);
    const [favoriteItems, setFavoriteItems] = React.useState([]);
    const [allItems, setAllItems] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const fadeValue = React.useRef(new Animated.Value(0)).current;
    const bones = [
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.9 , height: 30, marginTop: 15, borderRadius: 10},
        {width: Dimensions.get('screen').width * 0.5 , height: 10, marginTop: 5, borderRadius: 10}
    ]


    React.useEffect(() => {
        Animated.timing(fadeValue, {
            toValue: 1,
            useNativeDriver:true,
            duration: 1000
        }).start();
        
        async function getData(){
            setLoading(true);
            const responseFav = await fb.getFavorites();
            const responseNew = await fb.getNew();
            const responseAll = await fb.getAll();
            setNewItems(responseNew);
            setFavoriteItems(responseFav);
            setAllItems(responseAll);
            setLoading(false);
            fadeValue.setValue(0);
            Animated.timing(fadeValue, {
                toValue: 1,
                useNativeDriver:true,
                duration: 2000
            }).start();
        }
        // getData();

        // refresh data every time screen enter in focus
        const unsubscribe1 = navigation.addListener('focus', getData);
        const unsubscribe2 = navigation.removeListener('blur', getData);

    }, []);

    // tabs (pages)
    const New = () => (
        <TabItem>
            <Animated.ScrollView style={{opacity: fadeValue}}>
                {/* <SkeletonContent
                    isLoading={loading}
                    containerStyle={{width: loading ? '90%' : '100%', alignSelf: 'center'}}
                    layout={bones}
                > */}
                    {
                        generateList(newItems)
                    }
                {/* </SkeletonContent> */}
            </Animated.ScrollView>
        </TabItem>
    );

    const Favorites = () => (
        <TabItem>
            <Animated.ScrollView style={{opacity: fadeValue}}>
            {/* <SkeletonContent
                    isLoading={loading}
                    containerStyle={{width: loading ? '90%' : '100%', alignSelf: 'center'}}
                    layout={bones}
                > */}
                    {
                        generateList(favoriteItems)
                    }
                {/* </SkeletonContent> */}
            </Animated.ScrollView>
        </TabItem>
    );

    const All = () => (
        <TabItem>
            <Animated.ScrollView style={{opacity: fadeValue}}>
            {/* <SkeletonContent
                    isLoading={loading}
                    containerStyle={{width: loading ? '90%' : '100%', alignSelf: 'center'}}
                    layout={bones}
                > */}
                {
                    generateList(allItems)
                }
            {/* </SkeletonContent> */}
            </Animated.ScrollView>
        </TabItem>
    );

    function generateList(list){
        return list.map((item, i) => (
                <CustomListItem
                    key={i}
                    i={i}
                    title={item.title}
                    rightIcon={<AntDesign name='rightcircleo' size={25} color={Colors.primaryColor} />}
                    subtitle={`${item.from} - ${new Date(item.createdDate).toLocaleDateString('en-US')}`}
                    new={!item.isRead}
                    onPress={() => {
                        navigation.navigate('ItemFeedback', { Feedback: item })
                    }}
                />
        ))
    }

    // tab routes and configuration
    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        {key: 'new', title: 'New'},
        {key: 'favorites', title: 'Favorites'},
        {key: 'all', title: 'All'}
    ]);
    const renderScene = SceneMap({
        new: New,
        favorites: Favorites,
        all: All,
    });

    return(
        <Background colors={[Colors.primaryColor, Colors.secondaryColor]} start={[1, 1]} end={[0,0]}>
            <View>
                <Logo source={require('../../assets/images/logo.png')} />
                <Title>My Feedback</Title>
            </View>
            <TabView
                navigationState={{index, routes}}
                renderScene={renderScene}
                onIndexChange={setIndex}
                renderTabBar={renderTabBar}
                timingConfig={{duration: 1000}}
            />
        </Background>
    )
}