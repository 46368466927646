import * as React from 'react';
import { TouchableOpacity, ScrollView } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

import Header from '../../../components/Header';
import Colors from '../../../constants/colors';
import { useNavigation } from '@react-navigation/native';
import { Container, Title, Subtitle } from './styles';
import InteractionQuestion from '../../../components/InteractionQuestion';
import feedback from '../../../api/feedback';

export default function InteractionSummary({ route }){
    
    const navigation = useNavigation();
    
    const { id } = route.params;
    const [interaction, setInteraction] = React.useState({});

    React.useEffect(() => {
        async function getData(){
            setInteraction(await feedback.getRelatedInteraction(id));
            console.log('get the interaction data');
        }
        getData();
    }, []);


    // MOCK DATA
    const mock = {
        submittedDate: '2020-04-24T21:13:13.737Z',
        submittedBy: 'Name Here',
        responses: [
            {
                objectName: 'Question 1',
                questionText: 'Has the patient gone to the ER?',
                response: 'No',
            },
        ]
    }

    function back(){
        return(
            <TouchableOpacity onPress={() => navigation.goBack()}>
                <AntDesign name='leftcircleo' size={40} color={Colors.primaryColor}/>
            </TouchableOpacity>
        );
    }

    return(
        <Container>
            <Header solidBar leftButton={back}/>
            <Title>Interaction Date Here</Title>
            <Subtitle>Related Interaction Answers</Subtitle>
            <ScrollView>
                {
                    interaction.responses && interaction.responses.map((question, i) => (
                        <InteractionQuestion title={question.objectName} question={question.questionText} response={question.response} />
                    ))
                }
            </ScrollView>
        </Container>
    )
}