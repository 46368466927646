import * as React from 'react';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import { Container, HeaderBg, Logo, TextHeader, ScreenTitle, TeamTitle, Spacing, BackButton } from './styles';
import CustomListItem from '../../components/CustomListItem';
import Colors from '../../constants/colors';
import careteam from '../../api/care-team';

export default function MyCareTeam(){
    
    const navigation = useNavigation();

    // all providers from API
    const [providers, setProviders] = React.useState([]);

    // all caregivers from API
    const [caregivers, setCaregivers] = React.useState([]);

    React.useEffect(() => {
        // get all providers from API
        async function getData(){
            setProviders(await careteam.getAllProviders());
            setCaregivers(await careteam.getAllCaregivers());
        }
        getData();
    }, [])
    
    return(
        <Container>
            <TextHeader>
                <HeaderBg/>
                <Logo source={require('../../assets/images/logo.png')} />
                <ScreenTitle>My Care Team</ScreenTitle>
                <BackButton onPress={() => navigation.goBack()}>
                    <AntDesign name='leftcircleo' size={40} color={Colors.noticeText} />
                </BackButton>
            </TextHeader>
            <TeamTitle>My Providers</TeamTitle>
            {
                providers.map((prov, i) => (
                    <CustomListItem
                        key={i}
                        title={`${prov.name}, ${prov.role}`}
                        rightIcon={<AntDesign name='rightcircleo' size={25} color={Colors.primaryColor} />}
                        bottomDivider
                        onPress={() => navigation.navigate('ProviderDetail', {Provider: prov})}
                    />
                ))
            }
            <Spacing />
            <TeamTitle>Linked Care Givers</TeamTitle>
            {
                caregivers.map((care, i) => (
                    <CustomListItem
                        key={i}
                        title={`${care.name}, ${care.relationship}`}
                        rightIcon={<AntDesign name='rightcircleo' size={25} color={Colors.primaryColor} />}
                        bottomDivider
                        onPress={() => navigation.navigate('CaregiverDetail', {Caregiver: care})}
                    />
                ))
            }
        </Container>
    )
}