import utils from './utils';
import api from '../constants/api'
import axios from 'axios';

export async function getNew(){
    try{
        const response = await axios.get(`${api.baseUrl}/v1/feedback?filter=unread&offset=0&count=50`, {headers: { Authorization: await utils.getAuth()}});
        // console.log('NEW: ', response.data)
        console.log('get new');
        return response.data.results;
    }
    catch(e){
        console.log('Error getting new items', e);
    }
    
}

export async function getFavorites(){
    try{
        const response = await axios.get(`${api.baseUrl}/v1/feedback?filter=favorites&offset=0&count=50`, {headers: { Authorization: await utils.getAuth()}});
        console.log('get favorite');
        return response.data.results;
    }
    catch(e){
        console.log('Error getting favorite items', e)
    }
}

export async function getAll(){
    try{

        const response = await axios.get(`${api.baseUrl}/v1/feedback?filter=none&offset=0&count=50`, {headers: { Authorization: await utils.getAuth()}});
        console.log('get all');
        return response.data.results;
    }
    catch(e){
        console.log('Error getting all items');
    }
}

export async function favoriteItem(id, value){
    const response = axios.patch(`${api.baseUrl}/v1/feedback/${id}/favorite`, { value: !value }, { headers: {Authorization: await utils.getAuth()}});
    console.log('Item favorited? ', !value);
    return response.data;
}

export async function readItem(id, value){
    const response = axios.patch(`${api.baseUrl}/v1/feedback/${id}/read`, { value: !value }, { headers: {Authorization: await utils.getAuth()}});
    console.log('Item read? ', !value);
    return response.data;
}

export async function getFeedbackItem(id){
    const response = await axios.get(`${api.baseUrl}/v1/feedback/${id}`, {headers: {Authorization: await utils.getAuth()}})
    return response.data;
}

export async function getRelatedInteraction(id){
    try{
        const response = await axios.get(`${api.baseUrl}/v1/interactions/${id}`, {headers: {Authorization: await utils.getAuth()}})
        return response.data;
    }
    catch(e){
        console.log('Error getting related interaction', e);
        return {};
    }
}

export default {
    getNew,
    getFavorites,
    getAll,
    favoriteItem,
    readItem,
    getFeedbackItem,
    getRelatedInteraction,
}