import api from '../constants/api';
import utils from './utils';
import axios from 'axios';

async function getChart(start, end){
    try{
        const response = await axios.get(`${api.baseUrl}/v1/data-visualizer/composite-score?from=${start}&to=${end}`, {headers: {Authorization: await utils.getAuth()}});
        return response.data;
    }
    catch(e){
        console.log('Error on get chart data', e);
    }
}

export default {
    getChart,
}