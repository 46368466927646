import * as React from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
//import { SplashScreen } from 'expo';
import * as SplashScreen from 'expo-splash-screen'
import * as Font from 'expo-font';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useLinking from './src/navigation/useLinking';

import Routes from './src/routes';

let customFonts = {
  'space-mono': require('./src/assets/fonts/SpaceMono-Regular.ttf'),
  'SFProDisplayBold': require('./src/assets/fonts/SFProDisplayBold.ttf'),
  'SFProTextBold': require('./src/assets/fonts/SFProTextBold.ttf'),
  'SFProTextLight': require('./src/assets/fonts/SFProTextLight.ttf'),
};

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [setInitialNavigationState] = React.useState();
  const containerRef = React.useRef();
  const { getInitialState } = useLinking(containerRef);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();
        // Load our initial navigation state
        setInitialNavigationState(await getInitialState());

        // Load fonts
        await Font.loadAsync({customFonts});

      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }
    loadResourcesAndDataAsync();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
        <SafeAreaProvider>
          <View style={styles.container}>
            {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
            <Routes />
          </View>
        </SafeAreaProvider>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});