import React from "react";

import { 
  ScrollView, 
  TouchableOpacity,
  Image, 
  Alert} from 'react-native';

import { AntDesign, EvilIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Spinner from 'react-native-loading-spinner-overlay';

import {    Container, 
            Datetime,
            TitleHeader, 
            Textarea, 
            TextBottom, 
            TextHint,
            ContainerButton,
            ImageList,
            ImageName } from './styles';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Colors from '../../../constants/colors';
import * as ImagePicker from 'expo-image-picker';
import checkinphoto from '../../../api/checkinfiles';

export default function AddNoteScreen({ route }){

  const navigation = useNavigation();
  const { metricCollectorId } = route.params;

  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  function back(){
    return(
        <TouchableOpacity onPress={() => navigation.goBack()}>
            <AntDesign name='leftcircleo' size={40} color={Colors.primaryColor}/>
        </TouchableOpacity>
    );
  }

  async function pickImages(){
    try{
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            aspect: [4, 3],
            quality: 1,
        });
        if(!result.cancelled){
            setImages([`${result.uri}`, ...images])
        }
    }
    catch(e){
        console.log(e);
    }
  }

  async function removeImage(image){
      const newImages = images.filter(item => item !== image);
      setImages(newImages);
  }

  async function submitImages(){
    if(images.length > 0){
      console.log('Submit images');
      setLoading(true);
      console.log( checkinphoto.submitPhotos(images, metricCollectorId, complete))
    }else{
      Alert.alert('Select at least one image!');
    }
  }

  function complete(response){
    setImages([]);
    setLoading(false);
    Alert.alert(response.success ? 'Upload complete!' : 'Error uploading!');
    navigation.goBack();
  }

    return(
      <ScrollView> 
        <Container>
          <Header solidBar leftButton={back}/>
          <Datetime>{new Date().toDateString()}</Datetime>
          <TitleHeader>Upload Media to Provider</TitleHeader>
          <Button text="ADD PHOTOS" color='#0053a1' textColor='#fff' onPress={pickImages}>
            <EvilIcons name='camera' size={20} color={'white'}/>
          </Button>
          {
              images.map((image, i) => (
                  <ImageList key={i}>
                      <Image source={{uri: image}} style={{height: 50, width: 50}} />
                      <ImageName>{image.split('/').pop()}</ImageName>
                      <TouchableOpacity onPress={() => removeImage(image)}>
                        <AntDesign name='close' size={30} color='#0053a1' />
                      </TouchableOpacity>
                  </ImageList>
              ))
          }
          <TextHint> If this is a medical emergency, please call 911 immediately. </TextHint>
          <ContainerButton>
            <Button text="Submit Entry" color='#0053a1' textColor='#fff' onPress={submitImages} />
          </ContainerButton>
        </Container>
        <Spinner
          visible={loading}
          textContent={'Uploading...'}
          overlayColor='rgba(0, 83, 159,0.9)'
          color='#fff'
          textStyle={{color: '#fff'}}
          /> 
      </ScrollView>

    )
}
