import styled from 'styled-components/native';
import Colors from '../../constants/colors';

export const Container = styled.View`
    flex: 1;
`
export const Information = styled.View`
    height : 110px;
    border-bottom-width: 1px;
    background-color: #f3f4f6;
    border-bottomColor: #d3d3d3;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 50px;
`

export const ButtonGroup = styled.View`
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom-width: 1px;
    background-color: ${Colors.tabBar};
    border-bottomColor: ${Colors.tabIconDefault};
`

export const Warning = styled.Text`
    color: #c91e31;
    font-weight: bold;
    width: 60%;
    text-align: center;
    align-self: center;
    margin-top: 30px;
`

export const InformationText = styled.Text`
    font-weight: bold;
    font-size: 16px;
`

export const InformationImage = styled.Image`
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 100px;
    resize-mode: cover;
`
export const ContactTitle = styled.Text`
    border-bottom-color: ${Colors.tabIconDefault};
    border-bottom-width: 1px;
    border-top-color: ${Colors.tabIconDefault};
    padding-vertical: 15px;
    font-size: 18px;
    padding-left: 20px;
`
export const Bold = styled.Text`
    font-weight: bold;
`