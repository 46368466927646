const tintColor = '#2f95dc';
const primaryColor = '#00539f';
const secondaryColor = '#02b2c1';

export default {
  tintColor,
  tabIconDefault: '#ababab',
  tabIconSelected: 'white',
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  primaryColor: primaryColor,
  secondaryColor: secondaryColor,
  projectBlue: '#0053A1',
  crimson: '#710A19',
};