import * as React from 'react';
import { ScrollView, View, TouchableOpacity, Dimensions, Animated } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

import { Container, Title, Subtitle, FeedbackText, ButtonGroup } from './styles';
import Colors from '../../constants/colors';
import Header from '../../components/Header';
import FeedbackNote from '../../components/FeedbackNote';
import FooterButton from '../../components/FooterButton';
import CustomListItem from '../../components/CustomListItem';
import fb from '../../api/feedback';
// import SkeletonContent from 'react-native-skeleton-content';

export default function ItemFeedback({ route }){

    const navigation = useNavigation();
    const { Feedback } = route.params;

    const [feedbackItem, setFeedbackItem] = React.useState({});
    const [isread, setisRead] = React.useState(Feedback.isRead);
    const interactions = [
        {
            title: 'Interaction Summary',
        },
    ];
    const [loading, setLoading] = React.useState(true);
    const fadeAnim = React.useRef(new Animated.Value(0)).current;
    const fadeAnim2 = React.useRef(new Animated.Value(0)).current;

    React.useEffect(() => {
        Animated.timing(fadeAnim2,{
            useNativeDriver:true,
            toValue: 1,
            duration: 1000
        }).start();

        async function getData(){
            setFeedbackItem(await fb.getFeedbackItem(Feedback.id));

            if(Feedback.isRead === false){
                read();
                setisRead(true);
            }
             setLoading(false);
             Animated.timing(fadeAnim,{
                toValue: 1,
                useNativeDriver:true,
                duration: 1000
            }).start();
        }
        getData();
    }, [])

    function back(){
        return(
            <TouchableOpacity onPress={() => navigation.goBack()}>
                <AntDesign name='leftcircleo' size={40} color={Colors.primaryColor}/>
            </TouchableOpacity>
        );
    }

    async function favorite(){
        await fb.favoriteItem(Feedback.id, feedbackItem.isFavorited);
    }

    async function read(){
        await fb.readItem(Feedback.id, Feedback.isRead);
        Feedback.isRead = !Feedback.isRead;
        setisRead(!isread);
    }

    return(
        <Container>
            <Animated.ScrollView style={{opacity: fadeAnim2}}>
                <Header solidBar leftButton={back}/>
                {/* <SkeletonContent
                    isLoading={loading}
                    layout={[
                        {width: 150, height: 20},
                        {width: 300, height: 15, marginTop: 10},
                        {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                        {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                        {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                        {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                        {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                        {width: Dimensions.get('screen').width * 0.4, height: 15, marginTop: 20},

                    ]}
                > */}
                    <Title style={{opacity: fadeAnim}}>{new Date(Feedback.createdDate).toDateString()}</Title>
                    <Subtitle style={{opacity: fadeAnim}}>{Feedback.title}</Subtitle>
                    <FeedbackText style={{opacity: fadeAnim}}>{feedbackItem.content && `\t${feedbackItem.content.split('\n').join('\n\t')}`}</FeedbackText>
                {/* </SkeletonContent> */}
                <FeedbackNote title='DELIVERED BY' name={Feedback.from} data={new Date(Feedback.createdDate).toLocaleTimeString()}/>
            </Animated.ScrollView>
            <View>
                {
                    feedbackItem.relatedInteractionId && interactions.map((int, i) => (
                        <CustomListItem
                            key={i}
                            title={int.title}
                            rightIcon={<AntDesign name='rightcircleo' size={24} color={Colors.primaryColor} />}
                            onPress={() => navigation.navigate('InteractionSummary', {id : feedbackItem.relatedInteractionId})}
                        />
                    ))
                }
                <ButtonGroup style={{opacity: fadeAnim}}>
                    <FooterButton checked={feedbackItem.isFavorited} title={'ADD TO\nFAVORITES'} active='heart' inactive='hearto' onPress={favorite}/>
                    <FooterButton checked={isread} title={'MARK AS\nREAD'} active='checkcircle' inactive='checkcircleo' onPress={read}/>
                </ButtonGroup>
            </View>
        </Container>
    )
}