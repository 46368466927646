import { StyleSheet } from 'react-native';
import Colors from '../../constants/colors';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Bullet = styled.View`
    background-color: ${Colors.primaryColor};
    height: 8px;
    width: 8px;
    border-radius: 10px;
`

export default StyleSheet.create({
    container: {
        backgroundColor: '#F2F4F6',
        height: 60,
        borderBottomWidth: 1,
        borderBottomColor: '#CDCED3',

    },
    titleNew: {
        fontSize: 16,
        color: Colors.primaryColor,
        fontWeight: 'bold',
    },
    titleRead: {
        fontSize: 16,
    },
    subtitle: {
        fontSize: 12,
        fontWeight: 'bold',
    },
})