import React from "react";

import { Modal, StyleSheet, View, TouchableOpacity} from 'react-native';

import {  
  HeaderModal,
  ModalCenterView,
  HeaderModalText,
  CancelContainer,
  CancelText,
  ContentModal } from './styles';


export default function Popup(props){

  return(
      <Modal
          animationType="fade"
          transparent={true}
          visible={props.stateModal}
        >
          <View style={styles.centeredView}>
          <TouchableOpacity style={styles.outside} onPress={props.onClose}></TouchableOpacity>
            <ModalCenterView>
              <HeaderModal>
                <HeaderModalText> {props.titleModal} </HeaderModalText>
              </HeaderModal>

              <ContentModal>
                 {props.contentModal}
              </ContentModal>

              <CancelContainer
                onPress={props.onClose}>
                <CancelText>CANCEL</CancelText>
              </CancelContainer>
              
            </ModalCenterView>
          </View>
        </Modal>
  )
} 

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(255,255,255,0.6)',
    zIndex: 1,
  },
  outside: {
    zIndex: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
  }
});



