import axios from 'axios';
import api from '../constants/api';
import utils from './utils';

export async function token(phoneNumber, pin) {
  
  const body = {
    phoneNumber,
    pin,
  };
  console.log(`body of data123`, body);

  try {
    const { data } = await axios.post(`${api.baseUrl}/v1/token`, body,utils.withHeaders(),{})
    return data;
  } catch (err) {
    return false;
  }
};

export async function refreshToken(accessToken, refreshToken) {
  const body = {
    accessToken,
    refreshToken,
  };

  try {
    const { data } = await axios.post(`${api.baseUrl}/v1/refresh=tToken`, body, utils.withHeaders());
   
    return data;
  } catch (err) {
    console.log(`Error getting Refresh Token`, err);
  }
};

export async function requestPinReset(phoneNumber) {
  const body = {
    phoneNumber,
  };

  try {
    const response = await axios.post(`${api.baseUrl}/v1/request-pin-reset`, body, utils.withHeaders());
    return {success: true, response};
  } catch (err) {
    console.log(`Error getting Request Pin Reset`, err);
    return {success: false, err};
  }
};

export default {
  token,
  refreshToken,
  requestPinReset,
};