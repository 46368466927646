import styled from 'styled-components/native';
import { TextArea } from 'react-native';

export const InputContainer = styled.View`
  flex: 1;
  flex-direction: column;
  background-color: #F7F9FB;
  align-items: center;
`

export const InputText = styled.Text`
  margin: 10px 0;
  font-size: 13px;
  color: #5A5A5A;
`

export const TextBox = styled.TextInput`
  width: 90%;
  height: 90%;
  margin: 10px 20px 0 20px;
  padding: 20px;
  backgroundColor: white;
  text-align-vertical: top;
`