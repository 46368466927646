import React from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';

export default StyleSheet.create({ 
  shadow:{
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    elevation: 6,
  }
})

const Container = styled.View`
  flex: 1;
  justify-content: center;
  text-align: center;
`;

const Datetime = styled.Text`
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
`;

const TitleHeader = styled.Text`
  color: rgb(0, 0, 0);
  font-weight: 100;
  font-size: 24px;
  text-align: center;
  border-bottom-width: 1px;
  border-bottom-color: #D8D8D8;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const Textarea = styled.TextInput`
  color: rgb(0, 0, 0);
  width: 90%;
  margin: auto;
  padding: 20px;
  text-align-vertical: top;
  background-color: #FFFFFF;
  min-height: 380px;
  margin-top: 15px;
  font-size:15px;
`;


const TextBottom = styled.Text`
  color: #5A5A5A;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
`;

const TextHint = styled.Text`
  color: #7F7F7F;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const ContainerButton = styled.View`
  width:90%;
  margin:0 auto;
  justify-content: center;
  text-align: center;
`;

const Subject = styled.TextInput`
  color: rgb(0, 0, 0);
  width: 90%;
  margin: auto;
  padding: 20px;
  text-align-vertical: top;
  background-color: #FFFFFF;
  margin-top: 15px;
  font-size:15px;
  box-shadow:  2px 2px 8px rgb(218, 218, 218);
`





export {  Container, 
          Datetime, 
          TitleHeader, 
          Textarea, 
          TextHint, 
          TextBottom,
          ContainerButton,
          Subject };
