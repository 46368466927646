import * as React from 'react';
import { Audio } from 'expo-av';
import * as Permissions from 'expo-permissions';
import * as FileSystem from 'expo-file-system';
import { Alert } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import Spinner from 'react-native-loading-spinner-overlay';

import { Container, Timer, Cancel, ButtonGroup, Play } from './styles';
import Button from '../../../components/Button';
import Popup from '../../../components/Modal';
import daily from '../../../api/daily-interaction';

// this variable must be placed here
// each recording instance can be used ONLY ONCE
var RECORDING = new Audio.Recording();
var SOUND = null;
var ms;

export default function RecordVoice(props){

    const [isRec, setIsRec] = React.useState(false);
    const [timer, setTimer] = React.useState(null);
    const [playing, setPlaying] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [haveSound, setHaveSound] = React.useState(true);

    React.useEffect(() => {
        SOUND = null;
        setHaveSound(true);
    }, []);

    async function startRecording(){
        try{
            await Audio.setAudioModeAsync({
                allowsRecordingIOS: true,
                interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
                playsInSilentModeIOS: true,
                shouldDuckAndroid: true,
                interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
                playThroughEarpieceAndroid: false,
                staysActiveInBackground: true,
            });
            RECORDING = new Audio.Recording();
            SOUND = null;
            setHaveSound(true);
            setTimer(null);
            await Permissions.askAsync(Permissions.AUDIO_RECORDING);
            await RECORDING.prepareToRecordAsync({
                android: {
                  extension: '.m4a',
                  outputFormat: Audio.RECORDING_OPTION_ANDROID_OUTPUT_FORMAT_MPEG_4,
                  audioEncoder: Audio.RECORDING_OPTION_ANDROID_AUDIO_ENCODER_AAC,
                  sampleRate: 44100,
                  numberOfChannels: 2,
                  bitRate: 128000,
                },
                ios: {
                    extension: '.m4a',
                    outputFormat: Audio.RECORDING_OPTION_IOS_OUTPUT_FORMAT_MPEG4AAC,
                    audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_MIN,
                    sampleRate: 44100,
                    numberOfChannels: 2,
                    bitRate: 128000,
                    linearPCMBitDepth: 16,
                    linearPCMIsBigEndian: false,
                    linearPCMIsFloat: false,
                  },
              });

            await RECORDING.startAsync();
            console.log('started recording')
            setIsRec(true);
            ms = setInterval(async () => {
                const time = await RECORDING.getStatusAsync();
                setTimer(time.durationMillis);
            }, 1000);
        }
        catch(e){
            console.log('Error starting recording ', e);
        }
    }

    async function stopRecording(){
        try{
            clearInterval(ms);
            const response = await RECORDING.getStatusAsync()
            console.log(response);
            setIsRec(false);

            // stop recording 
            await RECORDING.stopAndUnloadAsync();
            console.log('stop recording');

            var { sound } = await RECORDING.createNewLoadedSoundAsync();
            SOUND = sound;
            setHaveSound(false);
        }
        catch(e){
            console.log('Error stop ', e);
        }
    }

    async function trash(){
        try{
            if(!isRec){
                // create a new recording instance
                RECORDING = new Audio.Recording();
                SOUND = null;
                setHaveSound(true);
                setTimer(null);
            }
        }
        catch(e){
            console.log('Error trash ', e);
        }
    }

    async function play(){
        try{
            await Audio.setAudioModeAsync({
                allowsRecordingIOS: false,
                interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
                playsInSilentModeIOS: true,
                playsInSilentLockedModeIOS: true,
                shouldDuckAndroid: true,
                interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
                playThroughEarpieceAndroid: false,
                staysActiveInBackground: true,
            });
            if(SOUND !== null)
            {
                setPlaying(true);
                SOUND.setOnPlaybackStatusUpdate((playbackStatus) => {
                    if(playbackStatus.didJustFinish){
                        stop();
                    }
                })
                await SOUND.playAsync();
            }
        }
        catch(e){
            console.log(e);
        }
    }

    async function stop(){
        try{
            setPlaying(false);
            await SOUND.stopAsync();
        }
        catch(e){
            console.log(e);
        }
    }

    async function submit(){
        try{
            if(isRec === false && SOUND !== null){
                setLoading(true);
                const info = await FileSystem.getInfoAsync(RECORDING.getURI());
                const response = await daily.uploadAudio(info.uri);
                console.log('filepath', response);
                props.submit(response);

                setLoading(false);
                props.return();
            }
            else{
                Alert.alert('Record something!');
            }
        }
        catch(e){
            Alert.alert('You must record something!');
        }
    }

    return(
        <Container>
            <Timer>{new Date(timer).toISOString().slice(11, -5)}</Timer>
            <ButtonGroup>
                <Button text={isRec ? 'STOP' : 'RECORD'} onPress={isRec ? stopRecording : startRecording}/>
                <Play disabled={haveSound} onPress={playing ? stop : play}><FontAwesome name={playing ? 'stop-circle' : 'play-circle'} size={55} color={ haveSound ? '#66ff8f' : (playing ? '#AC162C' : '#219c42')} /></Play>
                <Cancel disabled={haveSound} onPress={trash}><FontAwesome name='trash' size={30} color='#fff'/></Cancel>
            </ButtonGroup>
            <Button onPress={submit} text='SUBMIT AUDIO' />

            <Popup  
                titleModal='Recording...'
                contentModal={
                    <Container>
                        <Timer>{new Date(timer).toISOString().slice(11, -5)}</Timer>
                        <Button text='STOP RECORDING' onPress={stopRecording} />
                    </Container>
                }
                onClose={() => {
                    stopRecording();
                }}
                stateModal={isRec} 
            />
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                overlayColor='rgba(0, 83, 159,0.9)'
                color='#fff'
                textStyle={{color: '#fff'}}
            />
        </Container>
    )
}