import React from 'react';
import { StyleSheet } from 'react-native';
import Constants from 'expo-constants';

export default StyleSheet.create({
    background: {
        backgroundColor: '#eee',
        flex: 1,
    },
    screen: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        alignSelf: 'center'
    },
    content: {
        width: '85%',
    },
    contentImage: {
        height: 80,
        width: 80,
        marginBottom: 20,
    },
    title: {
        paddingTop: Constants.statusBarHeight + 80, 
        color: '#9B9B9B',
        fontSize: 16,
        paddingBottom: 100,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    linkText: {
        fontSize: 14,
        color: '#0053A1',
        fontWeight: '600',
        paddingTop: 10,
        paddingBottom: 20,
        textAlign: 'right',
    },

    loginButton: {
        marginTop: 80,
    },
    inputMask: {
        borderBottomWidth: 1,
        borderBottomColor: '#000', 
        fontSize: 14, 
        paddingVertical: 2
    }
});