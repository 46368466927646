import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Dimensions, Animated } from 'react-native';
import { ListItem } from 'react-native-elements'
import { ScrollView } from 'react-native-gesture-handler';
import { LinearGradient } from 'expo-linear-gradient';
import Colors from '../../../constants/colors';
import { Logo } from '../../../components/Logo';
import { AntDesign } from '@expo/vector-icons';
import library from '../../../api/library';

import { useNavigation, useRoute } from '@react-navigation/native';
// import SkeletonContent from 'react-native-skeleton-content';

// this page gets data from /v1/care-library/topics/{topicId}/articles
export default function TopicArticles() {
  
  const navigation = useNavigation();
  const route = useRoute();
  const topic = route.params.topic;

  const [topicArticles, setTopicArticles] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const fadeAnim = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.timing(fadeAnim,{
      toValue: 1,
      useNativeDriver:true,
      duration: 1000
    }).start();

    async function getData(){
      const response = await library.getTopicArticles(topic.id);
      setTopicArticles(response);

      setLoading(false);
      Animated.timing(fadeAnim,{
        toValue: 1,
        duration: 1000,
        useNativeDriver:true,
      }).start();
    }
    getData();
  }, [])



  function navigateBack() {
    navigation.goBack();
  }

  function navigateToArticle(article){
    navigation.navigate('ArticleDetails', { article });
  }
  
  return (
    <View style={styles.container}>
      <LinearGradient colors={[Colors.secondaryColor, Colors.primaryColor]} 
          style={{ height: '100%' }}>
        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
          <View style={styles.header}>
            <View style={styles.backArrow}>
              <TouchableOpacity onPress={navigateBack}>
                <AntDesign name="leftcircleo" size={40} color={ Colors.noticeText } />
              </TouchableOpacity>
            </View>           
            <Logo style={styles.logo} size={{width: 88, height:79}}></Logo>
            <Text style={styles.titleHeader}>{topic.name}</Text>
            <Text style={styles.subtitletitleHeader}>Library > {topic.name} </Text>

          </View>
          <View style={styles.containerLibraryTopics}>
            <Animated.View style={[styles.containerList, {opacity: fadeAnim}]}>
            {/* <SkeletonContent
                isLoading={loading}
                containerStyle={{width: '100%'}}
                layout={[
                  { width: Dimensions.get('screen').width, height: 60, marginTop: 5, alignSelf: 'center'},
                  { width: Dimensions.get('screen').width, height: 60, marginTop: 5, alignSelf: 'center'},
                  { width: Dimensions.get('screen').width, height: 60, marginTop: 5, alignSelf: 'center'},
                  { width: Dimensions.get('screen').width, height: 60, marginTop: 5, alignSelf: 'center'},
                  { width: Dimensions.get('screen').width, height: 60, marginTop: 5, alignSelf: 'center'},

                ]}
              > */}
            {topicArticles.results && topicArticles.results.map((item, i) => (
                <ListItem
                  onPress={() => navigateToArticle(item)}
                  containerStyle={styles.listItem}
                  key={i}
                  title={item.name}
                  titleStyle={{ color: '#000', fontSize: 20 }}
                  rightIcon={ <AntDesign name="rightcircleo" size={30} color={ Colors.primaryColor } /> }
                  bottomDivider
                />
              ))
            }
            {/* </SkeletonContent> */}
            </Animated.View>
          </View>
        </ScrollView>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingTop: 35,
  },
  header: {
    position: 'relative',
    width: '100%',
    // height: 256,
    flex: 1,
    alignItems: 'center',
    borderBottomColor:'#CDCED3',
    borderBottomWidth:0.5,
    paddingBottom: 20,
    marginTop: 15,
  },
  backArrow: {
    left: 30,
    top: '35%',
    position: 'absolute',
  },
  logo: {
    marginBottom: 20,
    height: 110,
    width: 110,
  },
  titleHeader: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingTop: 20,  
    // fontFamily: 'SFProTextLight',
    fontWeight: '100',
    lineHeight: 29,
  },  
  subtitletitleHeader:{
    color: '#FFFFFF',
    fontSize: 10,
    fontWeight: 'bold',
    paddingTop: 10,  
  },
  containerLibraryTopics: {
    backgroundColor: '#F2F4F6',
    flex: 1,
    alignItems: 'center',
    
  },
  titleTopic: {
    color: '#000000',
    fontSize: 22,
    lineHeight: 26,
    paddingVertical:20,
    borderBottomColor: '#CDCED3',
    borderBottomWidth: 2.5,
    borderStyle: 'solid',
    width: '100%',
    textAlign: 'center',

  },
  containerList: {
    backgroundColor: '#F2F4F6',
    width: '100%',
    flex: 1,
    paddingHorizontal:10,
    paddingBottom: 10,
  },
  listItem:{
    backgroundColor: '#F2F4F6',
  },


});
