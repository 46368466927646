import React from 'react';
import { ListItem } from 'react-native-elements';
import { TouchableOpacity } from 'react-native-gesture-handler';

import styles from './styles';

export default function YesNoOption(option,selected,onSelection,index){

  const containerStyle = selected ? [styles.normalStyle, styles.selectedStyle] : styles.normalStyle;
  const titleStyle = selected ? styles.selectedTextStyle : styles.normalTextStyle;

  return(
    <TouchableOpacity onPress={onSelection} key={index}>
      <ListItem
        key={index}
        title={option}
        containerStyle={containerStyle}
        titleStyle={titleStyle}
        bottomDivider
      />
    </TouchableOpacity>
  )
}
