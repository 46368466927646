import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';

import { TextStyles } from './styles'

export default function TextStyled (props) {
    return(
      <TextStyles {...props}>
        {props.text && props.text}
      </TextStyles>
    )
}

TextStyled.propTypes = {
    text: PropTypes.string.isRequired,
    title: PropTypes.any,
    question: PropTypes.any,
    message: PropTypes.any,
    warning: PropTypes.any,
};