import React, { useState }  from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import PropTypes from 'prop-types';
import Colors from '../../constants/colors';
import Constants from 'expo-constants';

import styles, { Container, IconContainer, Icon, Bar, BottomBarContainer, RightButtonContainer, LeftButtonContainer } from './styles';

function BottomBar(props) {
  const LeftButton = props.leftButton;
  const RightButton = props.rightButton;

  return(
    <BottomBarContainer>
      {props.leftButton 
      && <LeftButtonContainer>
        <LeftButton/>
      </LeftButtonContainer>
      }
      {props.rightButton
      && <RightButtonContainer>
        <RightButton/>
      </RightButtonContainer>
      }
    </BottomBarContainer>
)};

export default function Header(props) {
  const headerColor = [Colors.primaryColor, Colors.secondaryColor];
  var iconWidth = '88px';
  var iconHeight = '80px';
  var topMargin = '0';

  if (props.transparentL || props.gradientL) {
    iconWidth = '210px';
    iconHeight = '190px';
    topMargin = `${30 + Constants.statusBarHeight}px`;
  } else if (props.gradient) {
    iconWidth = '162px';
    iconHeight = '146px';
    topMargin =`${Constants.statusBarHeight + 15}px`;
  }

  return (
    <Container accessibilityRole='header'>
      {props.gradient && 
        <LinearGradient 
          style={{height: 150}} 
          colors={headerColor} 
          start={[0, 0.5]} 
          end={[1, 0.5]}
        />
      }
      {props.gradientL && 
        <LinearGradient 
          style={{height: 170}} 
          colors={headerColor} 
          start={[0, 0.5]} 
          end={[1, 0.5]}
        />
      }
      { props.solidBar && 
        <Bar style={{ backgroundColor: Colors.projectBlue}}/>
      }
      { props.transparent && 
        <Bar style={{ backgroundColor: 0}}/>
      }
      { props.transparentL && 
        <Bar style={{ backgroundColor: 0}}/>
      }
      <IconContainer style={styles.shadow} >
        <Icon 
          source={require('../../assets/images/logo.png')} 
          accessibilityLabel="Hunstmann Cancer Institute"
          iconWidth={iconWidth}
          iconHeight={iconHeight}
          topMargin={topMargin}
        />
      </IconContainer>
      <BottomBar {...props}/>
    </Container>
  );
}

Header.propTypes = {
  gradient: PropTypes.any,
  gradientL: PropTypes.any,
  solidBar: PropTypes.any,
  transparent: PropTypes.any,
  transparentL: PropTypes.any,
  leftButton: PropTypes.func,
  rightButton: PropTypes.func,
}
