import React, { useState, useEffect } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import Spinner from 'react-native-loading-spinner-overlay';
import { Audio } from 'expo-av';

import Header from '../../components/Header';
import Button from '../../components/Button';
import TextStyled from '../../components/TextStyled';
import Answer from '../../components/Answer';
import ProgressBar from '../../components/ProgressBar';
import EndScreen from './EndScreen';
import Popup from '../../components/Modal';
import RecordVoice from './RecordVoice';

import daily from '../../api/daily-interaction';

import { Container, AnswerContainer, ProgressBarContainer } from './styles'
import { Alert } from 'react-native';


export default function DailyInteractionScreen({ navigation }) {
  const [answered, setAnswered] = useState(false);
  const [answer, setAnswer] = useState('');
  const [buttonText, setButtonText] = useState("Start your report");
  const [progress, setProgress] = useState('0%');
  const [q, setQuestion] = useState({});
  const [warning, setWarning] = useState(true);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(1);

  // record voice modal
  const [voiceVisible, setVoiceVisible] = useState(false);

  const warningText = 'If you have a Medical Emergency CALL 911 Immediately';

  //api connnections
  async function getFirstQuestion(){
    setLoading(true);
    const response = await daily.start();
    setQuestion(response);
   // console.log('SSSSSSSSSSSSSSS',response);
    setToken(response.continuationToken);
    setTotalQuestions(response.details.numberOfQuestions);
    calculateProgress(1, response.details.numberOfQuestions);
    setLoading(false); 
  }

  async function getQuestion(answer){
    setLoading(true);
    setAnswered(false);
    setQuestion({});
    const response = await daily.take(String(answer), token);
    setQuestion(response);
    setWarning(false);
    setButtonText("Next question");
    setLoading(false);
    setAnswer('');
    setQuestionNumber(questionNumber+1);
    calculateProgress(questionNumber, totalQuestions);
  }

  async function onClickBackButton(){
    await daily.abandon(token);
  }

  async function endInteraction(token){
           navigation.navigate('Home');
  };

  //useEffects
  useEffect(() => {
    getFirstQuestion();
  }, []);

  useEffect(() => {
    return function clean(){
      if(token !== ""){
        onClickBackButton();
      }
    }
  }, [token])

  //header buttons
  function assistiveAudio(){
    return(
      <TouchableOpacity onPress={() => handleAudioButton()}>
        <AntDesign name='playcircleo' size={40} color='#0053A1'/>
      </TouchableOpacity>
    );
  }

  function recordAudio(){
    return(
      <TouchableOpacity onPress={() => {
        setVoiceVisible(true);
      }}>
        <FontAwesome5 name='microphone' size={40} color='#0053A1'/>
      </TouchableOpacity>
    )
  }

  async function handleAudioButton(){
    try {
      const { sound, status } = await Audio.Sound.createAsync( { uri: q?.question.audioFileUrl });
      sound.playAsync();
      // Your sound is playing!
    } catch (error) {
      console.log(error);
      Alert.alert('Some error has occurred!', 'Sorry! Some error has occurred playing the audio, please contact us!')
    }
  }

  //others
  function calculateProgress(current, total){
    const number = Math.floor((current / total) * 100);
    const string = String(number).concat('%');
    setProgress(string);
  }

  function handleAnswer(a){
     if(a === "[]" ){
       setAnswered(false);
     }
     else{
       setAnswered(true);
     }
    setAnswer(a);
  }

  if(q?.status === 'Complete'){
    return (
      <Container>
        <Header solidBar />
        <EndScreen />
        <Button text="Submit My Report" color="#0053A1" textColor='white' 
          style={{ alignSelf: 'center', marginBottom: 60 }}
          onPress={() => {
            endInteraction(token);
          }}
        />
      </Container>
    )
  }
  else{
    // console.log("1", loading)
    return (
      <Container> 
        <AnswerContainer style={{ flex: 1, flexDirection: 'column' }}>
          <Header solidBar 
            rightButton={q?.question &&  q?.question.questionType === 'TextVoice' ? recordAudio : (q?.question && q?.question.audioFileUrl ? assistiveAudio : null)}
          />
          {q?.question && <TextStyled question text={q?.question.questionText} />}
          {q?.question && <Answer 
            type={q?.question.questionType}
            metadata={q?.question.questionMetadata}
            onAnswer={handleAnswer}
          />}
           </AnswerContainer>
          {q?.question && <TextStyled message text={q?.question.helpText}/>}
          {warning && <TextStyled warning text={warningText}/>}
          { answered && <Button 
            text={buttonText} color="#0053A1" textColor='white' 
            style={{ alignSelf: 'center' }}
            onPress={() => getQuestion(answer)}
          /> }
          <ProgressBarContainer>
            <TextStyled title style={{ fontSize: 14 }} text={'Interaction Progress'}/>
            <ProgressBar progress={progress} />
          </ProgressBarContainer>
         {voiceVisible&&
          <Popup  
          titleModal='Record Voice'
          contentModal={<RecordVoice return={() => setVoiceVisible(false)} submit={getQuestion}/>}
          onClose={() => setVoiceVisible(false)}
          stateModal={voiceVisible} />
         }
         {loading&&
           <Spinner
           visible={loading}
           textContent={'Loading...'}
           overlayColor='rgba(0, 83, 159,0.9)'
           color='#fff'
           textStyle={{color: '#fff'}}
           />
          }   
      </Container>
    );
  }  
}

