import * as React from 'react';
import { TouchableOpacity, Dimensions, ScrollView, Animated, Platform, Easing } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import Spinner from 'react-native-loading-spinner-overlay';
import * as ScreenOrientation from 'expo-screen-orientation';

import { Container, Title, WeekArea, WeekText, WeekButton, SubtitleContainer, SubtitleText, SubtitleColor, Subtitles, TurnScreen, TurnScreenText, Close } from './styles';
import Colors from '../../constants/colors';
import Header from '../../components/Header';
import chartData from '../../api/chartData';

export default function ReportsScreen(){

    const navigation = useNavigation();

    const [loading, setLoading] = React.useState(false);
    const [turnScreen, setTurnScreen] = React.useState(true);
    const [startWeek, setStartWeek] = React.useState(new Date());
    const [endWeek, setEndWeek] = React.useState(new Date());

    const [chartWidth, setChartWidth] = React.useState(Dimensions.get('screen').width);
    const [chartHeight, setChartHeight] = React.useState(Dimensions.get('screen').height);

    const spinValue = React.useRef(new Animated.Value(0)).current;
    const spin = spinValue.interpolate({
        inputRange: [0,1],
        outputRange: ['0deg', '360deg']
    })

    const [data, setData] = React.useState({
        labels: ['Loading...'],
        datasets: [
            {
            data: [0],
            color: () => '#000',
        }],
    });
    const [subtitles, setSubtitles] = React.useState([]);

    var lineColors = [
        '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
    ];

    React.useEffect(() => {

        Animated.loop(
            Animated.timing(spinValue,{
                toValue: 1,
                duration: 1000,
                useNativeDriver:true,
                easing: Easing.linear,

            })
        ).start();

        async function rotateScreen(){
            if(Platform.OS === 'android'){
                await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.ALL);
            }
            if(Platform.OS === 'ios'){
                await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.DEFAULT);
            }
        }
        rotateScreen();

        var date = new Date();
        var first = new Date(date.setDate(date.getDate() - date.getDay() + 1))
        var last = new Date(date.setDate(date.getDate() - date.getDay() + 7));
        
        //console.log(`${first.toISOString().slice(0, 10)} and ${last.toISOString().slice(0, 10)}`)
        
        setStartWeek(first);
        setEndWeek(last);

        getData(first, last);

        // update de chart size
        Dimensions.addEventListener('change', chartSize);

        return () => {
            Dimensions.removeEventListener('change', chartSize);
        }

    }, []);

    function chartSize(){
        if(turnScreen === true){
            setTurnScreen(false);
        }
        
        setChartWidth(Dimensions.get('screen').width);
        setChartHeight(Dimensions.get('screen').height);
    }

    async function getData(firstDay, lastDay){
        setLoading(true);
        // console.log(`first ${firstDay} last day ${lastDay}`)
        const response = await chartData.getChart(firstDay.toISOString().slice(0, 10), lastDay.toISOString().slice(0, 10));
        // console.log(response.dataAxes);

        var subs = [];
        var newData = {
            labels: response.labels,
            datasets: [{
                data: [0],
                color: () => '#000',
            }],
        };

        if(response.averageDataPoints.length > 0){
            newData.datasets.push({
                data: response.averageDataPoints,
                color: () => '#666',
                strokeWidth: 3 // optional
            });
            subs.push({label: 'Average Score', color: '#666'});
        }

        if(response.dataAxes.length > 0){
            response.dataAxes.forEach((obj) => {
                var color = lineColors.pop();
                newData.datasets.push({
                    data: obj.data,
                    color: () => color,
                    strokeWidth: 3,
                })
                subs.push({label: obj.label, color: color});
            });
        }
        
        setData(newData);
        setSubtitles(subs);
        setLoading(false);
    }

    function back(){

        async function lockScreen(){
            await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
        }

        return(
            <TouchableOpacity onPress={() => {navigation.goBack(); lockScreen()}}>
                <AntDesign name='leftcircleo' size={40} color={Colors.primaryColor}/>
            </TouchableOpacity>
        );
    }

    function lastWeek(){
        const first = new Date(startWeek);
        first.setDate(first.getDate() - 7);
        setStartWeek(first);

        const last = new Date(endWeek);
        last.setDate(last.getDate() - 7);
        setEndWeek(last);

        getData(first, last);
    }

    function nextWeek(){
        const first = new Date(startWeek);
        first.setDate(first.getDate() + 7);
        setStartWeek(first);

        const last = new Date(endWeek);
        last.setDate(last.getDate() + 7);
        setEndWeek(last);

        getData(first, last);
    }

    const chartConfig = {
        backgroundGradientFrom: "#000",
        backgroundGradientFromOpacity: 0,
        backgroundGradientTo: "#fff",
        backgroundGradientToOpacity: 0,
        color: () => '#666',
        strokeWidth: 2,
        fillShadowGradientOpacity: 0,
    };

    return (
        <ScrollView>
            <Container>
                <Header solidBar leftButton={back}/>
                <Title>Reports Chart</Title>
                <WeekArea>
                    <TouchableOpacity onPress={lastWeek}>
                        <WeekButton><AntDesign name='caretleft' size={14} /> Last Week</WeekButton>
                    </TouchableOpacity>
                    <WeekText>{startWeek.toISOString().slice(0, 10)} - {endWeek.toISOString().slice(0, 10)}</WeekText>
                    <TouchableOpacity onPress={nextWeek}>
                        <WeekButton>Next Week <AntDesign name='caretright' size={14} /></WeekButton>
                    </TouchableOpacity>
                </WeekArea>

                <Subtitles>
                    {
                        subtitles.map((s, i) => (
                            <SubtitleContainer key={i}>
                                <SubtitleColor color={s.color} />
                                <SubtitleText>{s.label}</SubtitleText>
                            </SubtitleContainer>
                        ))
                    }
                </Subtitles>

                <LineChart 
                    data={data}
                    width={chartWidth}
                    height={chartHeight * 0.7}
                    chartConfig={chartConfig}
                    getDotColor={() => '#666'}
                    verticalLabelRotation={20}
                />

            </Container>
            {loading &&
                <Spinner
                visible={loading}
                textContent={'Loading...'}
                overlayColor='rgba(0, 83, 159,0.9)'
                color='#fff'
                textStyle={{color: '#fff'}}
                />
            }
            {
                turnScreen && 
                <TurnScreen>
                    <Close onPress={() => setTurnScreen(false)} />
                    <Animated.View style={{transform: [{rotate: spin}]}}>
                        <MaterialIcons name='rotate-right' size={100} color='#fff'/>
                    </Animated.View>
                    <TurnScreenText>You can turn the screen for better visualization! Touch to dismiss!</TurnScreenText>
                </TurnScreen>
            }
        </ScrollView>
    )
}