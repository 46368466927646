import React from 'react';
import { ListItem } from 'react-native-elements';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { AntDesign } from '@expo/vector-icons';

import Colors from '../../constants/colors'
import { Circle } from './styles';

export default function SingleOption(option, selected, onSelect, index){
  const icon = selected ? <AntDesign name={'checkcircle'} size={35} color={Colors.projectBlue}/> : <Circle />

  return(
    <TouchableOpacity onPress={onSelect} key={index}>
      <ListItem
        title={option.label}
        containerStyle={{ height: 65 }}
        titleStyle={{ fontSize: 20, textAlign: 'left' }}
        bottomDivider
        rightIcon={icon} 
      />
    </TouchableOpacity>
  )
}