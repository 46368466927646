import utils from './utils';
import api from '../constants/api';
import axios from 'axios';

// /v1/care-library/topics
async function getTopics(){
    const response = await axios.get(`${api.baseUrl}/v1/care-library/topics`, {headers: {Authorization: await utils.getAuth()}});
    return response.data;
}

// /v1/care-library/topics/{topicId}
async function getTopicContent(id){
    const reqUrl = `${api.baseUrl}/v1/care-library/topics/${id}`;
    try{
        const response = await axios.get(reqUrl, {headers: {Authorization: await utils.getAuth()}});
        return response.data;
    }
    catch(e){
        console.log(e);
    }
}

// /v1/care-library/topics/{topicId}/articles
async function getTopicArticles(id){
    const response = await axios.get(`${api.baseUrl}/v1/care-library/topics/${id}/articles`, {headers: {Authorization: await utils.getAuth()}});
    return response.data;
}

// /v1/care-library/articles/{articleId}
async function getArticle(id){
    const response = await axios.get(`${api.baseUrl}/v1/care-library/articles/${id}`, {headers: {Authorization: await utils.getAuth()}});
    return response.data;
}

export default {
    getTopics,
    getTopicContent,
    getTopicArticles,
    getArticle,
}