import React from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';


export default StyleSheet.create({ 
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    elevation: 6,
  }
})

export const IconContainer = styled.View.attrs(props => ({
  width: props.iconWidth || '88px',
  height: props.iconHeight || '80px',
  top: props.topMargin || '40px'
}))`
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  top: ${props => props.top};
  justify-content: center;
  background-color: transparent;
  border-radius: 10px;
  align-self: center;
`;

export const Icon = styled.Image.attrs(props => ({
  width: props.iconWidth || '88px',
  height: props.iconHeight || '80px',
  top: props.topMargin || '40px'
}))`
  width: ${props => props.width};
  height: ${props => props.height};
  top: ${props => props.top};
  border-radius: 10px;
  border-color: white;
  align-self: center;
`;

export const Bar = styled.View`
  height: 60px;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.View`
    background-color: #F2F4F6;
    width: 100%;
    height: 120px;
    margin-bottom: 20px;
`

export const BottomBarContainer = styled.View`
  background-color: 0;
  width: 100%;
  height: 60px;
  flex: 1;
  flex-direction: row;
`

export const RightButtonContainer = styled.View`
  margin-top: 20px;
  position: absolute;
  right: 20px; 
`

export const LeftButtonContainer = styled.View`
  margin-top: 20px;
  position: absolute;
  left: 20px; 
`

