import React from 'react';
import { TextInput, StyleSheet } from 'react-native';

import styles from './styles';

export default function Input(props){
    return(
        <TextInput 
            style={styles.input}
            textContentType={props.textContentType}
            placeholder={props.placeholder}
            keyboardType={props.keyboardType}
            returnKeyType={props.returnKeyType}
            onClick={TextInput.focus}
            secureTextEntry={props.secureTextEntry}
            onChangeText={props.onChangeText}
        >
        </TextInput>
    )
}