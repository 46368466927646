import * as React from 'react';
import { View, TouchableOpacity, Linking } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import CustomListItem from '../../components/CustomListItem';
import Colors from '../../constants/colors';
import Header from '../../components/Header';
import { InfoGroup, Title, Subtitle, ContactTitle, Warning, Bold } from './styles';
import careteam from '../../api/care-team.js';

export default function CaregiverDetail({ route }){
    
    const navigation = useNavigation();
    
    // Caregiver data from previous page (id, name and role)
    const { Caregiver } = route.params;

    // Caregiver data from API
    const [caregiverData, setCaregiverData] = React.useState({});

    React.useEffect(() => {
        async function getData(){
            setCaregiverData(await careteam.getCaregivers(Caregiver));
        }
        getData();
    }, []);

    function callPhone(){
        Linking.openURL(`tel:${caregiverData.contactPhoneNumber}`);
    }

    function sendEmail(){
        Linking.openURL(`mailto:${caregiverData.contactEmail}`);
    }

    function back(){
        return(
            <TouchableOpacity onPress={() => navigation.goBack()}>
                <AntDesign name='leftcircleo' size={40} color={Colors.primaryColor}/>
            </TouchableOpacity>
        );
    }

    return (
        <View>
            <Header solidBar leftButton={back}/>
            <InfoGroup>
                <Title>{Caregiver.name}</Title>
                <Subtitle>{Caregiver.relationship}</Subtitle>
                <Subtitle>Joined your team on {new Date(caregiverData.joinedDate).toLocaleDateString()}</Subtitle>
            </InfoGroup>
            { 
                (caregiverData.contactPhoneNumber !== '' || caregiverData.contactEmail !== '') && 
                <ContactTitle>How to Contact: <Bold>{Caregiver.name}</Bold></ContactTitle>
            }
            { caregiverData.contactPhoneNumber !== '' && <CustomListItem
                title={`\tCall Phone`}
                rightIcon={<AntDesign name='rightcircleo' size={25} color={Colors.primaryColor} />}
                bottomDivider
                onPress={callPhone}
            />}
            { caregiverData.contactEmail !== '' && <CustomListItem
                title={`\tSend Email`}
                rightIcon={<AntDesign name='rightcircleo' size={25} color={Colors.primaryColor} />}
                bottomDivider
                onPress={sendEmail}
            />}
            <Warning>If You Have A Medical Emergency CALL 911 Immediately</Warning>
        </View>
    )
}