import * as React from 'react';
import { Container, Label } from './styles';
import Colors from '../../constants/colors';

export default function TabBarIcon(props) {
  return (
      <Container {...props}>
        <props.fontFamily
          name={props.name}
          size={props.focused ? 28 : 22}
          color={props.focused ? Colors.tabIconSelected : Colors.tabIconDefault}
        />
        <Label {...props}>{props.title}</Label>
      </Container>
  );
}
