import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {NumberInputContainer, NumberTextInput, InputText} from './styles';

export default function NumberInput(props) {

  function handleValue(typedValue){

    let value = parseInt(typedValue);

    if(value > props.max) {
      value = props.max;
    } else if (value < props.min || isNaN(value)) {
      value = props.min;
    }
    props.onChangeText(value);
  }

  return(
    <>
      <NumberInputContainer>
        <NumberTextInput {...props}
          keyboardType={'numeric'}
          value={String(props.value)}
          onChangeText={(text) => handleValue(text)}
          placeholder='0'
          returnKeyType='done'
        />
      </NumberInputContainer>
      <InputText>Tap Above To Type</InputText>
    </>
  );
}