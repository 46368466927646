import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Linking, Animated, Dimensions } from 'react-native';
import { ListItem } from 'react-native-elements'
import { ScrollView } from 'react-native-gesture-handler';
import { LinearGradient } from 'expo-linear-gradient';
import Colors from '../../../constants/colors';
import { Logo } from '../../../components/Logo';
import { AntDesign } from '@expo/vector-icons';
import library from '../../../api/library';

import { useNavigation, useRoute } from '@react-navigation/native';
// import SkeletonContent from 'react-native-skeleton-content';

export default function ArticleDetails() {
  
  const navigation = useNavigation();
  const route = useRoute();
  const article = route.params.article;

  const [articleData, setArticleData] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const fadeAnim = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.timing(fadeAnim,{
      toValue: 1,
      useNativeDriver:true,
      duration: 1000
    }).start();

    async function getData(){
      setArticleData(await library.getArticle(article.id));
      setLoading(false);
      Animated.timing(fadeAnim,{
        toValue: 1,
        useNativeDriver:true,
        duration: 1000
      }).start();
    }
    getData();
  }, [])

  const listActions = [
    {
      title: 'Learn More',
    },
    {
      title: 'Other Link here',
    },
  ];

  function navigateBack() {
    navigation.goBack();
  }
  
  return (
    <View style={styles.container}>
      <LinearGradient colors={[Colors.secondaryColor, Colors.primaryColor]} 
          style={{ height: '100%' }}>
        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
          <View style={styles.header}>
            <View style={styles.backArrow}>
              <TouchableOpacity onPress={navigateBack}>
                <AntDesign name="leftcircleo" size={40} color={ Colors.noticeText } />
              </TouchableOpacity>
            </View>           
            <Logo style={styles.logo} size={{width: 88, height:79}}></Logo>
            {/* <SkeletonContent
              isLoading={loading}
              layout={[
                {width: 200, height: 20, marginTop: 10},
                {width: 100, height: 10, marginTop: 10}
              ]}
            > */}
              <Animated.Text style={[styles.titleHeader, {opacity: fadeAnim}]}>{articleData.heading}</Animated.Text>
              <Animated.Text style={[styles.subtitletitleHeader, {opacity: fadeAnim}]}>Library > {articleData.breadcrumbs && `${articleData.breadcrumbs[0].topic} > ${articleData.breadcrumbs[1].topic}`} </Animated.Text>
            {/* </SkeletonContent> */}

          </View>
          
          <View style={styles.containerLibraryTopics}>
            <View style={styles.containerText}>
              {/* <SkeletonContent
                isLoading={loading}
                containerStyle={{width: '100%', alignItems: "center"}}
                layout={[
                  {width: 150, height: 20},
                  {width: 300, height: 15, marginTop: 10},
                  {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                  {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                  {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                  {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                  {width: Dimensions.get('screen').width * 0.9, height: 15, marginTop: 20},
                  {width: Dimensions.get('screen').width * 0.4, height: 15, marginTop: 20},

              ]}
              > */}
                <Animated.Text style={[styles.contentText, {opacity: fadeAnim}]}>
                  {articleData.content}
                </Animated.Text>
              {/* </SkeletonContent> */}
            </View>

            <Animated.View style={[styles.containerList, {opacity: fadeAnim}]}>
              {/* <SkeletonContent
                isLoading={loading}
                containerStyle={{width: '100%'}}
                layout={[
                  {width: Dimensions.get('screen').width, height: 50}
                ]}
              > */}
              {
                articleData.additionalResources && articleData.additionalResources.map((item, i) => (
                  <ListItem
                    onPress={() => Linking.openURL(item.url)}
                    containerStyle={styles.listItem}
                    key={i}
                    title={item.label}
                    titleStyle={{ color: '#000', fontSize: 20 }}
                    rightIcon={ <AntDesign name="rightcircleo" size={20} color={ Colors.primaryColor } /> }
                    topDivider
                    bottomDivider
                  />
                ))
              }
            {/* </SkeletonContent> */}
            </Animated.View>
          </View>
        </ScrollView>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingTop: 35,
  },
  header: {
    position: 'relative',
    width: '100%',
    // height: 256,
    flex: 1,
    alignItems: 'center',
    borderBottomColor:'#CDCED3',
    borderBottomWidth:0.5,
    paddingBottom: 20,
    marginTop: 15,
  },
  backArrow: {
    left: 30,
    top: '35%',
    position: 'absolute',
  },
  logo: {
    marginBottom: 20,
    height: 110,
    width: 110,
  },
  titleHeader: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingTop: 20,  
    // fontFamily: 'SFProTextLight',
    fontWeight: '100',
    lineHeight: 29,
  },  
  subtitletitleHeader:{
    color: '#FFFFFF',
    fontSize: 10,
    fontWeight: 'bold',
    paddingTop: 10,  
  },
  containerLibraryTopics: {
    backgroundColor: '#F2F4F6',
    flex: 1,
    alignItems: 'center',
    paddingTop: 30,
  },
  titleTopic: {
    color: '#000000',
    fontSize: 22,
    lineHeight: 26,
    paddingVertical:20,
    borderBottomColor: '#CDCED3',
    borderBottomWidth: 2.5,
    borderStyle: 'solid',
    width: '100%',
    textAlign: 'center',
  },
  containerText: {
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingBottom: 20,
  },
  contentDot:{
    paddingRight:15,
  },
  contentText: {
    fontSize:13,
    lineHeight: 20,
  },
  containerList: {
    backgroundColor: '#F2F4F6',
    width: '100%',
    flex: 1,
    paddingHorizontal:10,
    paddingBottom: 30,
    paddingTop:30,
    
  },
  listItem:{
    backgroundColor: '#F2F4F6',
  },


});
