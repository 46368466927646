import * as React from 'react';
import { AsyncStorage, Text } from 'react-native';
import { Background, Icon } from './styles';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation, CommonActions } from '@react-navigation/native';
import Toaster from 'react-native-root-toast';

import Colors from '../../constants/colors';
import CustomListItem from '../../components/CustomListItem';
import Popup from '../../components/Modal';
import ForgotPincode from './ForgotPincode';

export default function More(){
    
    const navigation = useNavigation();

    const [modalVisible, setModalVisible] = React.useState(false);

    const [toast, setToast] = React.useState(null);

    // the "action" field can be a function like 
    // () => navigation.navigate('ScreenRoute')
    const list = [
        {
            title: 'My Care Team',
            icon: 'rightcircleo',
            action: () => navigation.navigate('MyCareTeam')
        },
        {
            title: 'Change Passcode',
            icon: 'rightcircleo',
            action: () => toggleModal()
        },
        {
            title: 'Tutorial',
            icon: 'rightcircleo',
            action: () => navigation.navigate('FirstTimeScreen')
        },
        {
            title: 'Logout',
            icon: 'rightcircleo',
            action: () => logout(),
        },

    ];
    
    async function logout(){
        // clear token
        // try {
        //     await AsyncStorage.removeItem('TOKEN');
        //     console.log('token removed');
        // }
        // catch(e){
        //     console.log('Error removing TOKEN', e);
        // }
        console.log('Logout');

        navigation.dispatch(CommonActions.reset({
            index: 0,
            routes: [
                { name: 'Login' },
            ]
        }));
    }

    function toggleModal(){
        setModalVisible(!modalVisible);
    }

    function showToast(type, msg){
        setToast({text: msg, styles: 
            {
                container: {
                    marginTop: 50, 
                    backgroundColor: type === 'success' ? '#1ddca9' : '#ff4073',
                    width: 250,
                    padding: 10,
                    borderRadius: 100,
                    alignSelf: 'center',
                },
                text: {
                    color: '#fff',
                    textAlign: 'center',
                    fontWeight: 'bold',
                }
        }, duration: 1000}
        );
        setToast(null)
    }

    return (
        <Background colors={[Colors.secondaryColor, Colors.primaryColor]} start={[0, 0.1]} end={[1, 0.2]}>
            <Icon source={require('../../assets/images/logo.png')} />
            {
                list.map((item, i) => (
                    <CustomListItem
                        key={i}
                        i={i}
                        title={item.title}
                        rightIcon={<AntDesign name={item.icon} size={25} color={Colors.primaryColor} />}
                        onPress={item.action}
                    />
                ))
            }
            {modalVisible&&
                <Popup  
                titleModal="Forgot Pincode"
                contentModal={<ForgotPincode toast={showToast} return={() => toggleModal()}/>}
                onClose={() => toggleModal()} 
                stateModal={modalVisible} />
            }
            {toast&&
            <Toaster>{Toaster.show(toast,{
              duration: Toaster.durations.LONG,
              animation: true,
              hideOnPress: true,
            })}</Toaster>
          }
        </Background>
    )
}