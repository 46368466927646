import * as React from 'react';
import { Platform, Dimensions, View } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TabBarIcon from '../components/TabBarIcon';
import HomeScreen from '../screens/HomeScreen';
import FeedbackScreen from '../screens/FeedbackScreen';
import CheckinScreen from '../screens/CheckinScreen';
import LibraryScreen from '../screens/LibraryScreen';
import { MaterialCommunityIcons, Ionicons, AntDesign, Feather } from '@expo/vector-icons';
import More from '../screens/More';
import { useSafeArea } from 'react-native-safe-area-context';

const EXTRA_TABBAR_HEIGHT = 10;

const isLandscape = () => {
    const dim = Dimensions.get('screen');
    return dim.width >= dim.height;
};

const tabBarHeight = () => {
    const majorVersion = parseInt(Platform.Version, 10);
    const isIos = Platform.OS === 'ios';
    const isIOS11 = majorVersion >= 11 && isIos;
    if(Platform.isPad) return 49;
    if(isIOS11 && !isLandscape()) return 49;
    return 50;
}

const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = 'Home';

export default function BottomTabNavigator({ navigation, route }) {
  // Set the header title on the parent stack navigator depending on the
  // currently active tab. Learn more in the documentation:
  // https://reactnavigation.org/docs/en/screen-options-resolution.html
  navigation.setOptions({ headerTitle: getHeaderTitle(route) });

  const insets = useSafeArea();

    const tabBarOptions = {
        style: {
            ...Platform.select({
                ios: {
                    height: tabBarHeight() + EXTRA_TABBAR_HEIGHT + insets.bottom
                },
                android: {
                    height: tabBarHeight() + EXTRA_TABBAR_HEIGHT + insets.bottom,
                },
                web:{
                  height: tabBarHeight() + EXTRA_TABBAR_HEIGHT + insets.bottom,
                }
            })
        },
        showLabel: false,
        iconStyle: {
          width: 5,
        }
        
    };

    return (
    <BottomTab.Navigator tabBarOptions={tabBarOptions} initialRouteName={INITIAL_ROUTE_NAME}>
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: '',
          tabBarIcon: ({ focused }) => <View style={{flex: 1, minWidth: 80}}><TabBarIcon focused={focused} name="home" title={'Home'} fontFamily={AntDesign} /></View>,
        }}
      />
      <BottomTab.Screen
        name="Feedback"
        component={FeedbackScreen}
        options={{
          title: '',
          tabBarIcon: ({ focused }) => <View style={{flex: 1, minWidth: 80}}><TabBarIcon focused={focused} name="message-alert-outline" title={'Feedback'} fontFamily={MaterialCommunityIcons}  /></View>,
        }}
      />
      <BottomTab.Screen
        name="Check in"
        component={CheckinScreen}
        options={{
          title: '',
          tabBarIcon: ({ focused }) => <View style={{flex: 1, minWidth: 80}}><TabBarIcon focused={focused} name="check-square" title={'Check in'} fontFamily={Feather} /></View>,
        }}
      />
      <BottomTab.Screen
        name="Library"
        component={LibraryScreen}
        options={{
          title: '',
          tabBarIcon: ({ focused }) => <View style={{flex: 1, minWidth: 80}}><TabBarIcon focused={focused} name="book" title={'Library'} fontFamily={AntDesign} /></View>,
        }}
      />
      <BottomTab.Screen
        name="Links"
        component={More}
        options={{
          title: '',
          tabBarIcon: ({ focused }) => <View style={{flex: 1, minWidth: 80}}><TabBarIcon focused={focused} name="more-horizontal" title={'More'} fontFamily={Feather} /></View>,
        }}
      />
    </BottomTab.Navigator>
  );
}

function getHeaderTitle(route) {
  const routeName = route.state?.routes[route.state.index]?.name ?? INITIAL_ROUTE_NAME;

  switch (routeName) {
    case 'Home':
      return 'How to get started';
    case 'Links':
      return 'Links to learn more';
  }
}
