import React, { useEffect, useState, useRef } from 'react';
import { TouchableOpacity, Dimensions, Animated, Text } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { LinearGradient } from 'expo-linear-gradient';
import Colors from '../../constants/colors';
import Card from '../../components/Card';
import { Container, Logo, Header, Name, Datetime, ContainerCards } from './styles';
import { useNavigation } from '@react-navigation/native';
import dashboard from '../../api/dashboard';

// import SkeletonContent from "react-native-skeleton-content";

export default function HomeScreen() {
  const [name, setName] = useState('');
  const [library, setLibrary] = useState({});
  const [charts, setCharts] = useState({});
  const [checkins, setCheckins] = useState({});
  const [feedback, setFeedback] = useState({});
  const [interaction, setInteraction] = useState({});
  const [loadingData, setLoadingData] = useState(true);

  const fadeAnim = useRef(new Animated.Value(0)).current;
  const fadeAnim2 = useRef(new Animated.Value(0)).current;
  const fadeAnim3 = useRef(new Animated.Value(0)).current;
  const fadeAnim4 = useRef(new Animated.Value(0)).current;
  const fadeAnim5 = useRef(new Animated.Value(0)).current;
  const fadeAnimHeader = useRef(new Animated.Value(0)).current;
  
  // animated logo
  const animatedValue = useRef(new Animated.Value(0.5)).current;
  const animatedStyle = {
    transform: [{ scale: animatedValue }]
  }

  useEffect(() => {

    setTimeout(() => {
      Animated.spring(animatedValue, {
        toValue: 1,
        friction: 3,
        tension: 20,
      }).start();
    }, 600);

    setTimeout(() => {
      Animated.timing(fadeAnimHeader, {
        toValue: 1,
        useNativeDriver:true,
        duration: 2000
      }).start();
    }, 500);

    setTimeout(() => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        useNativeDriver:true,
        duration: 2000
      }).start();
    }, 800);

    setTimeout(() => {
      Animated.timing(fadeAnim2, {
        toValue: 1,
        useNativeDriver:true,
        duration: 2000
      }).start();
    }, 1000);

    setTimeout(() => {
      Animated.timing(fadeAnim3, {
        toValue: 1,
        useNativeDriver:true,
        duration: 2000
      }).start();
    }, 1200);

    setTimeout(() => {
      Animated.timing(fadeAnim4, {
        toValue: 1,
        useNativeDriver:true,
        duration: 2000
      }).start();
    }, 1400);

    setTimeout(() => {
      Animated.timing(fadeAnim5, {
        toValue: 1,
        useNativeDriver:true,
        duration: 2000
      }).start();
    }, 1600);

    async function getData(){
      const response = await dashboard.getDashboard();
      console.log(response);
      setName(response.careLibrary.name);
      setLibrary(response.careLibrary);
      setCharts(response.charts);
      setCheckins(response.checkIns);
      setFeedback(response.feedback);
      setInteraction(response.interaction);
      setLoadingData(false);
    }
    getData();
  }, []);

  const navigation = useNavigation();
  let headerCustomHeight=Dimensions.get('screen').height;
  return (
    <Container>
      <LinearGradient colors={[Colors.secondaryColor, Colors.primaryColor]} 
          style={{ height: '100%', }}>
            
           <ScrollView>
          <Animated.View style={{height:headerCustomHeight*0.3}}>
          <Header style={[animatedStyle]}>
            <Logo style={{opacity: fadeAnimHeader}} source={require('../../assets/images/logo.png')} />
            <Name style={{opacity: fadeAnimHeader}}>{name}</Name>
            <Datetime style={{opacity: fadeAnimHeader}}> {new Date().toDateString()} </Datetime>
          </Header>
          </Animated.View>
          {/* 1 card */}
          <Animated.View>
              <ContainerCards>
                <Animated.View style={{opacity: fadeAnim}}>
                  {/* <SkeletonContent
                    containerStyle={{width: '100%'}}
                    isLoading={loadingData}
                    duration={1200}
                    highlightColor='#fff'
                    boneColor='#eee'
                    layout={[
                      { width: Dimensions.get('screen').width * 0.9, height: 110, marginBottom: 10 },
                      ]}
                  > */}
                    { interaction.enabled && <TouchableOpacity onPress={() => navigation.navigate('DailyInteraction')}>
                      <Card
                        image={require('../../assets/images/home-card1.png')}
                        size={'large'}
                        title={`Daily\nInteraction`}
                        buttonLabel={`Start`}
                        ></Card>
                    </TouchableOpacity>}
                  {/* </SkeletonContent> */}
                </Animated.View>
                
                {/* 2 card */}
                <Animated.View style={{opacity: fadeAnim2}}>
                  {/* <SkeletonContent
                    containerStyle={{width: '100%'}}
                    isLoading={loadingData}
                    duration={1200}
                    highlightColor='#fff'
                    boneColor='#eee'
                    layout={[
                      { width: Dimensions.get('screen').width * 0.9, height: 110, marginBottom: 10 },
                      ]}
                  > */}
                  { feedback.enabled && <TouchableOpacity onPress={() => navigation.navigate('Feedback')}>
                    <Card
                      image={require('../../assets/images/home-card2.png')}
                      size={'small'}
                      title={`Feedback`}
                      notification={feedback.updates === 0 ? null : `${feedback.updates} Update(s)`}
                      buttonLabel={`Start`}
                      ></Card>
                  </TouchableOpacity>}
                  {/* </SkeletonContent> */}
                </Animated.View>

                {/* 3 card */}
                <Animated.View style={{opacity: fadeAnim3}}>
                  {/* <SkeletonContent
                    containerStyle={{width: '100%'}}
                    isLoading={loadingData}
                    duration={1200}
                    highlightColor='#fff'
                    boneColor='#eee'
                    layout={[
                      { width: Dimensions.get('screen').width * 0.9, height: 110, marginBottom: 10 },
                      ]}
                  > */}
                  { checkins.enabled && <TouchableOpacity onPress={() => navigation.navigate('Check in')}>
                    <Card
                      image={require('../../assets/images/home-card3.png')}
                      size={'small'}
                      title={`Check in`}
                      buttonLabel={`Start`}
                      notification={checkins.required !== 0 ? `${checkins.required} Required` : null}
                      statusText={checkins.required === checkins.completed ? 'Completed' : 'Not Yet Completed'}
                      ></Card>
                  </TouchableOpacity>}
                  {/* </SkeletonContent> */}
                </Animated.View>

                {/* 4 card */}
                <Animated.View style={{opacity: fadeAnim4}}>
                  {/* <SkeletonContent
                    containerStyle={{width: '100%'}}
                    isLoading={loadingData}
                    duration={1200}
                    highlightColor='#fff'
                    boneColor='#eee'
                    layout={[
                      { width: Dimensions.get('screen').width * 0.9, height: 110, marginBottom: 10 },
                      ]}
                  > */}
                  { library.enabled && <TouchableOpacity onPress={() => navigation.navigate('Library')}>
                    <Card
                      image={require('../../assets/images/home-card4.png')}
                      size={'medium'}
                      title={`My Care\nLibrary`}
                      buttonLabel={`Start`}
                      ></Card>
                  </TouchableOpacity>}
                  {/* </SkeletonContent> */}
                </Animated.View>

                {/* 5 card */}
                <Animated.View style={{opacity: fadeAnim5}}>
                  {/* <SkeletonContent
                    containerStyle={{width: '100%'}}
                    isLoading={loadingData}
                    duration={1200}
                    highlightColor='#fff'
                    boneColor='#eee'
                    layout={[
                      { width: Dimensions.get('screen').width * 0.9, height: 110, marginBottom: 10 },
                      ]}
                  > */}
                  { charts.enabled && <TouchableOpacity onPress={() => navigation.navigate('Reports')}>
                    <Card
                      image={require('../../assets/images/home-card5.png')}
                      size={'small'}
                      title={`Reports`}
                      buttonLabel={`Start`}
                      ></Card>
                  </TouchableOpacity>}
                  {/* </SkeletonContent> */}
                </Animated.View>

              </ContainerCards>
            
          </Animated.View>
        </ScrollView>
      </LinearGradient>
    </Container>
  );
};

