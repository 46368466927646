import styled from 'styled-components/native';

export const NumberInputContainer = styled.View`
  width: 115px;
  border-bottom-width: 1px;
  border-bottom-color: black;
`
export const NumberTextInput = styled.TextInput`
  text-align: center;
  font-size: 80px;
  font-weight: bold;
`
export const InputText = styled.Text`
  margin: 10px 0;
  font-size: 13px;
  color: #5A5A5A;
`
