import * as React from 'react';
import styles, { Box, BoxShadow, Title, Name, Date } from './styles'

export default function FeedbackNote(props){
    return(
        <Box>
            <BoxShadow style={styles.shadow}>
                <Title>{props.title}</Title>
                <Name>{props.name}</Name>
                <Date>{props.data}</Date>
            </BoxShadow>
        </Box>
    )
}