import styled from 'styled-components/native';
import Colors from '../../constants/colors';

export const Container = styled.View`
    align-items: center;
    background-color: ${Colors.noticeText};
    padding: 10px;
    elevation: 5;
    margin-bottom: 10px;
`

export const Title = styled.Text`
    font-size: 16px;
    color: ${Colors.primaryColor};
    text-align: center;
    margin-bottom: 5px;
`

export const Question = styled.Text`
    font-size: 14px;
    color: #000;
    text-align: center;
    padding-bottom: 5px;
`

export const Response = styled.Text`
    font-size: 20px;
    color: ${Colors.primaryColor};
    text-align: center;
`