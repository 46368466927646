import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import Colors from '../../constants/colors';
import { RFValue } from 'react-native-responsive-fontsize';

export const Button = styled.TouchableOpacity`
    width: 50%;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`
export const ButtonText = styled.Text`
    font-size: ${RFValue(10)}px;
    font-weight: bold;
    margin-left: 10px;
    text-align: center;
`
export default StyleSheet.create({
    btnIcon: {
        fontSize: 30,
        color: Colors.primaryColor,
    },
})