import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {NumberInputContainer, NumberTextInput, InputText} from './styles';

export default function NumericEntry(props) {
    const [val, setVal] = useState('');

    useEffect(() => {
      handleValue(val);
    }, [props.onChangeText])

  function handleValue(typedValue){
    let value = parseInt(typedValue);

    if(value > props.max) {
      value = props.max;
    } else if (value < props.min || isNaN(value)) {
      value = props.min;
    }
    setVal(String(value));
    props.onChangeText(value);
  }

  return(
    <>
      <NumberInputContainer>
        <NumberTextInput {...props}
          keyboardType={'numeric'}
          value={val || '0'}
          onChangeText={(text) => handleValue(text)}
          placeholderTextColor='#000'
          returnKeyType='done'
        />
      </NumberInputContainer>
      <InputText>Tap Above To Type</InputText>
    </>
  );
}