import utils from './utils';
import api from '../constants/api';
import axios from 'axios';

export async function getMedications(){
    const reqURL = `${api.baseUrl}/v1/checkin/medication`;
    const response = await axios.get(reqURL, {headers: {Authorization: await utils.getAuth()}})
    return response.data;
}

export async function submitMedication(data){
    try{
        console.log('data', data)
        const reqURL = `${api.baseUrl}/v1/checkin/submit`;
        const response = await axios.post(reqURL, {
            metricCollectorId: data.metricCollectorId,
            value: {
                medicationName: data.name,
                dosage: data.dosage
            }
        }, {headers: { Authorization: await utils.getAuth()}});
        console.log('Add med response', response.status);
        return {success: true, response};
    }
    catch(e){
        console.log('Error add medications', e);
        return {success: false, response: e};
    }
}

export default {
    getMedications,
    submitMedication,
}