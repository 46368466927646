import api from '../constants/api';
import utils from './utils';
import axios from 'axios';
import * as FileSystem from 'expo-file-system';

async function start() {

    const reqUrl = `${api.baseUrl}/v1/interactions/start`;
    try {
        const response = await axios.post(reqUrl, { "source": "Mobile" }, { headers: { Authorization: await utils.getAuth() } })
        if (response.status == 200) {
            return response.data;
        } else {
            let response = await axios.post(reqUrl, { "source": "Mobile" }, { headers: { Authorization: await utils.getAuth() } })
            return response.data;
        }

    }
    catch (e) {
        let response = await axios.post(reqUrl, { "source": "Mobile" }, { headers: { Authorization: await utils.getAuth() } })
        return response.data;

    }
}

async function take(value, continuationToken) {
    const reqUrl = `${api.baseUrl}/v1/interactions/take/${continuationToken}`;
    try {
        const response = await axios.post(reqUrl,
            {
                response: value,
                type: "response"
            },
            { headers: { Authorization: await utils.getAuth() } })

        if (response.status == 200) {
            return response.data;
        } else {

            const response = await axios.post(reqUrl,
                {
                    response: value,
                    type: "response"
                },
                { headers: { Authorization: await utils.getAuth() } })
            return response.data;
        }

    }
    catch (e) {
        const response = await axios.post(reqUrl,
            {
                response: value,
                type: "response"
            },
            { headers: { Authorization: await utils.getAuth() } })
        return response.data;

    }
}


async function end(continuationToken) {
    const reqUrl = `${api.baseUrl}/v1/interactions/end/${continuationToken}`;

    try {
        const response = await axios.post(reqUrl, null, { headers: { Authorization: await utils.getAuth() } })
        if (response.status == 200) {
            return response;
        } else {
            const response = await axios.post(reqUrl, null, { headers: { Authorization: await utils.getAuth() } })
            return response
        }

    }
    catch (e) {
        const response = await axios.post(reqUrl, null, { headers: { Authorization: await utils.getAuth() } })
        return response

    }
}

async function abandon(continuationToken) {
    const reqUrl = `${api.baseUrl}/v1/interactions/abandon/${continuationToken}`;

    try {
        const response = await axios.post(reqUrl, { source: 'mobile' }, { headers: { Authorization: await utils.getAuth() } });
        if (response.status == 200) {
            return response;
        } else {
            const response = await axios.post(reqUrl, { source: 'mobile' }, { headers: { Authorization: await utils.getAuth() } });
            return response;
        }
    }
    catch (e) {

    }
}

async function uploadAudio(audio) {
    try {

        let fileName = audio.split('/').pop();
        fileName = fileName.split('.')[0] + '.mp3';

        var file = await FileSystem.readAsStringAsync(audio, { encoding: FileSystem.EncodingType.Base64 });

        const fileResponse = await axios.post(`${api.baseUrl}/v1/checkin/upload`, { fileName: fileName, fileDataBase64: file }, { headers: { Authorization: await utils.getAuth() } });


        return { success: true, response: fileResponse };
    }
    catch (e) {

        return { sucess: false, response: e };
    }
}

export default {
    start,
    take,
    end,
    abandon,
    uploadAudio,
}