import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { PropTypes } from 'prop-types';

import RadioButtons from '../RadioButtons';
import NumberInput from '../NumberInput';
import NumberBarInput from '../NumberBarInput';
import YesNoOption from '../YesNoOption';
import SingleOption from '../SingleOption';
import MultipleOption from '../MultipleOption';
import NumericEntry from '../NumericEntry';

import { InputContainer, InputText, TextBox} from './styles';

export default function Answer(props) {
  const [answered, setAnswered] = useState(false);
  const [answer, setAnswer] = useState('');

  function handleAnswer(a){
    setAnswered(true);
    setAnswer(a);
    props.onAnswer(a);
  }

  useEffect(() => {
    if(props.type === "PromptFeedback"){
      handleAnswer('');
    }
  }, [props]);

  return(
    <View
      {...props}
      style={styles.container}
    >
      {{
        'PromptFeedback': (
          <View />
        ),
        'TextVoice': (
          <InputContainer style={{ height: 350 }}>
            <TextBox 
              multiline
              numberOfLines={25}
              placeholder={'Type text here'}
              placeholderTextColor={'#5A5A5A'}
              maxLength={props.maxCharacterCount}
              onChangeText={(text) => handleAnswer(text)}
              onSubmitEditing={(text) => handleAnswer(text)}
            />
           <InputText style={{ marginBottom: 50 }}>Tap Above To Type</InputText>
          </InputContainer>
        ),
        'NumericEntry': (
          <InputContainer>
            { props.metadata && <NumericEntry {...props} 
              max={props.metadata.maxValue} 
              min={props.metadata.minValue}
              onChangeText={handleAnswer}
            /> }
          </InputContainer>
        ),
        'ExperienceLevel': (
          <InputContainer>
            {  props.metadata && <NumberBarInput {...props} 
              max={props.metadata.upperValue} 
              min={props.metadata.lowerValue}
              response={handleAnswer}
            />}
          </InputContainer>
        ),
        'YesNo': (
          <>
            {props.metadata && <RadioButtons
              options={[props.metadata.trueLabel, props.metadata.falseLabel]}
              metadata={props.metadata}
              renderContainer={RadioButtons.renderVerticalContainer}
              renderOption={YesNoOption}
              onSelection={(a, index) => handleAnswer(index)}
            />}
          </>
        ),
        'MultipleChoice_Single': (
          <>
          { props.metadata &&  <RadioButtons
              options={props.metadata.items}
              renderContainer={RadioButtons.renderVerticalContainer}
              renderOption={SingleOption}
              onSelection={(a, index) => handleAnswer(index + 1)}
            />}
          </>
        ),
        'MultipleChoice_Multiple': (
          <>
            { props.metadata && <MultipleOption 
              options={props.metadata.items} {...props} 
              response={handleAnswer}
            />}
          </>
        ),
      }[props.type || 'promptFeedback']}
      {/* {answered && props.onAnswer()} */}
    </View>
  )
}


Answer.propTypes = {
  type: PropTypes.oneOf([
    'YesNo',
    'MultipleChoice_Multiple',
    'MultipleChoice_Single', 
    'NumericEntry', 
    'ExperienceLevel', 
    'TextVoice', 
    'PromptFeedback', 
    'End']),
  metadata: PropTypes.any,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F2F4F6',
    marginVertical: 20,
  },
});