import * as React from 'react';
import { Container, Item, ContentImage, Title, Subtitle, styles } from './styles';

export default function FirstTimeScreenItem(props){
    return(
        <Container {...props}>
            <Item>
                {props.data.image}
                {props.data.title}
                <Subtitle>{props.data.subtitle}</Subtitle>
                { React.Children.map(props.children, (child, i) => {
                    if(i < React.Children.count(props.children)-1){
                        let cloned = React.cloneElement(child, {
                            style: styles.middleText,
                        })
                        return cloned;
                    }
                })}
            </Item>
            { React.Children.map(props.children, (child, i) => {
                if(i == React.Children.count(props.children)-1){
                    return child
                }
            })}
        </Container>
    )
}