import React, { useState, createRef, useEffect } from 'react';
import { View, ScrollView, StyleSheet, Text, TouchableOpacity, AsyncStorage, Alert } from 'react-native';
import { useNavigation, CommonActions } from '@react-navigation/native';
import Spinner from 'react-native-loading-spinner-overlay';
import { KeyboardAvoidingView } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import Toaster from 'react-native-root-toast';
import { SplashScreen } from 'expo';

import Constants from 'expo-constants';
import Colors from '../../constants/colors';
import Header from '../../components/Header';
import Button from '../../components/Button';
import Input from '../../components/Input';

import styles from './styles';
import ForgotPincode from '../More/ForgotPincode';
import Popup from '../../components/Modal';

import { token } from '../../api/authentication';
import { cond } from 'react-native-reanimated';

export default function LoginScreen(){

    const navigation = useNavigation();
    
    const [modalVisible, setModalVisivle] = useState(false);
    const [telephone, setTelephone] = useState(''); 
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState({
      visible: false
    });

    const [toast, setToast] = useState(null);

    const saveToken = async (token) => {
      console.log('error fixed4 save token')
        try{
          console.log('error fixed5 save token--------',token)
          if(token != undefined){
            await AsyncStorage.setItem('TOKEN', token);
            console.log('error fixed6 save token')
          }else{
            setLoading({
              visible: false
            });
          }
            
        }
        catch(e){
            console.log('Error saving token', e);
        }
    }

    const submitLogin = async () => {
        setLoading({
          visible: true
        });
        try {
          const tokenResponse = await token(telephone, password);
          if(tokenResponse !=''){
            await saveToken(tokenResponse.accessToken);
            try {
              const value = await AsyncStorage.getItem('WALKTHROUGH');
              if (value === 'true') {
                navigation.dispatch(CommonActions.reset({
                    index: 0,
                    routes: [
                        { name: 'Home'},
                    ],
                }));
              } else {
                navigation.dispatch(CommonActions.reset({
                    index: 0,
                    routes: [
                        { name: 'FirstTimeScreen'},
                    ],
                }));
              }
            }catch(e){
              console.log('Error getting walkthrough storage:', e)
            }
            setLoading({
              visible: false
            });
          }else{
            setLoading({
              visible: false
            });
            setTimeout(() => {
              Alert.alert(
                "We’re sorry, the password you entered was not valid.",
                "Someone from the office will be contacting you to reset the password.",
                [
                  { text: "OK" }
                ],
              );
            }, 100);
          }
        } catch (err) {
          console.log('Error', err);
        }
    };
    
    function showToast(type, msg){
        setToast({text: msg, styles: 
            {
                container: {
                    marginTop: 50, 
                    backgroundColor: type === 'success' ? '#1ddca9' : '#ff4073',
                    width: 250,
                    padding: 10,
                    borderRadius: 100,
                    alignSelf: 'center',
                },
                text: {
                    color: '#fff',
                    textAlign: 'center',
                    fontWeight: 'bold',
                }
        }, duration: 1000}
        );
        setToast(null)
    }

    
    return(
        <ScrollView style={styles.background} pointerEvents='auto' keyboardShouldPersistTaps='handled'>
            <Header gradientL />
            <View style={styles.screen}>
                <View style={styles.content}>
                    <Text style={styles.title}>
                        Please sign in below
                    </Text>
                    <KeyboardAvoidingView behavior='position' keyboardVerticalOffset={140}>
                        <TextInputMask 
                            value={telephone} 
                            onChangeText={(text) => {
                                setTelephone(text)
                            }}
                            type={'custom'}
                            options={{mask: '9999999999', }}
                            style={styles.inputMask}
                            placeholder='Telephone Number'
                            keyboardType='number-pad'
                            returnKeyType='done'
                        />
                        <Input
                            keyboardType='number-pad'
                            textContentType='password'
                            placeholder='Pin Code'
                            onChangeText={(text) => setPassword(text)}
                            value={password}
                            secureTextEntry={true}
                            returnKeyType='done'
                        />
                        <TouchableOpacity onPress={() => setModalVisivle(true)}>
                            <Text style={styles.linkText}>
                                Forgot Pin Code
                            </Text>
                        </TouchableOpacity>
                    </KeyboardAvoidingView>
                    <Button style={styles.loginButton} 
                        text='Sign in'
                        color={Colors.primaryColor}
                        textColor='#fff'
                        onPress={submitLogin}
                    />
                </View>
                {loading.visible==true&&
                  <Spinner
                  cancelable={true}
                  visible={loading.visible}
                  textContent={'Loading...'}
                  overlayColor='rgba(0, 83, 159,0.9)'
                  color='#fff'
                  textStyle={{color: '#fff'}}
                  /> 
                }
                {modalVisible&&
                  <Popup  
                  titleModal="Forgot Pincode"
                  contentModal={<ForgotPincode toast={showToast} return={() => setModalVisivle(false)}/>}
                  onClose={() => setModalVisivle(false)} 
                  stateModal={modalVisible} 
                  />
                }
            </View>
           
          {toast&&
            <Toaster>{Toaster.show(toast,{
              duration: Toaster.durations.LONG,
              animation: true,
              hideOnPress: true,
            })}</Toaster>
          }

        </ScrollView>
    );
};