import styled from 'styled-components/native';


const ModalCenterView = styled.View`
  width: 90%;
  margin: auto;
  border-width: 1px;
  border-color: #000000;
  border-radius: 5px;
`;
const HeaderModal = styled.View`
  background-color: #000;
  padding-top: 15px;
  padding-bottom: 15px;
  width:100%;
`;

const HeaderModalText = styled.Text`
  color: #FFF;
  font-weight: 100;
  font-size: 24px;
  text-align: center;
`;

const CancelContainer = styled.TouchableHighlight`
  background-color: #F2F4F6;
  padding-top: 20px;
  padding-bottom: 20px;
  width:100%;
  border-top-width: 1px;
  border-top-color: #000000;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;
const CancelText = styled.Text`
  color: #0053A1;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`;
const ContentModal = styled.View`
  width:100%;
  background-color: #FFFFFF;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap:wrap;
  padding-top: 25px;
  padding-bottom: 25px;
`;

const TitleContent = styled.Text`
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`;


  

export {  
  ModalCenterView,
  HeaderModal,
  HeaderModalText,
  CancelContainer,
  CancelText,
  ContentModal,
  TitleContent };
