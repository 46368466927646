import styled from 'styled-components/native';
import Colors from '../../constants/colors';
import { StyleSheet, Animated } from 'react-native';

export const Container = styled.View`
    flex: 1;
    background-color: #eee;
`

export const ActiveDot = styled.View`
    background-color: ${Colors.primaryColor};
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
`

export const Bold = styled(Animated.Text)`
    font-weight: bold;
`
export const Italic = styled(Animated.Text)`
    font-style: italic;
`
export const WarningMore = styled(Animated.Text)`
    font-size: 20px;
`

export const ContentImage = styled(Animated.Image)`
    height: 80px;
    width: 75px;
    margin-bottom: 20px;
    margin-top: 15px;
    resize-mode: contain;
`

export const Title = styled(Animated.Text)`
    color: ${Colors.primaryColor};
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: bold;
    margin-bottom: 20px;
`

export default StyleSheet.create({
    swiper: {
        marginTop: 70,
        textAlign: 'center',
    },
    pagination: {
        position: 'absolute',
        top: 80,
        bottom: undefined
    }
})