import api from '../constants/api';
import utils from './utils';
import axios from 'axios';

async function getCheckin(){
    const reqUrl = `${api.baseUrl}/v1/checkin`;
    try{
        const { data } = await axios.get(reqUrl, { headers: {Authorization: await utils.getAuth()} })
        return data;
    }
    catch(e){
        console.log(e);
    }
}

async function submit(metricCollectorId, value){
    const reqUrl = `${api.baseUrl}/v1/checkin/submit`;
    try{
        const response = await axios.post(reqUrl, {metricCollectorId: metricCollectorId, value: value}, {headers: {Authorization: await utils.getAuth()}});
        console.log('resposta do submit: ', response);
        return response;
    }
    catch(e){
        console.log(e);
        return {success: false, response: e};
    }
}


async function submitNote(subject, content){
    const reqUrl = `${api.baseUrl}/v1/checkin/message`;
    try{
        const response = await axios.post(reqUrl, {subject, content}, {headers: {Authorization: await utils.getAuth()}});
        return response;
    }
    catch(e){
        console.log(e);
    }
}

export default {
    getCheckin,
    submit,
    submitNote,
}