import styled from 'styled-components/native';
import { Animated } from 'react-native';

const Container = styled.View`
  flex: 1;
`;
const Header = styled(Animated.View)`
  flex: 1;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 30px;
`;
const Logo = styled(Animated.Image)`
  margin-bottom: 10px;
  height: 125px;
  width: 125px;
  flex: 1;
  border-radius: 13px;
`;
const ContainerCards = styled(Animated.View)`
  width: 90%;
  margin: auto;
`;
const Name = styled(Animated.Text)`
  color: rgb(255, 255, 255);
  font-size: 17px;
  font-weight: bold;
`;

const Datetime = styled(Animated.Text)`
  color: rgb(255, 255, 255);
  font-weight: 100;
  font-size: 17px;
`;

export { Container, Header, Logo, ContainerCards, Name, Datetime };
