import * as React from 'react';
import { Text, AsyncStorage, Animated } from 'react-native';
import Swiper from 'react-native-swiper/src';
import { useNavigation } from '@react-navigation/native';

import Header from '../../components/Header';
import Button from '../../components/Button';
import FirstTimeScreenItem from '../../components/FirstTimeScreenItem';
import styles, { Container, ActiveDot, Bold, Italic, WarningMore, ContentImage, Title } from './styles';
import careteam from '../../api/care-team';

export default function FirstTimeScreen(){

    const fadeAnim = React.useRef(new Animated.Value(0)).current;
    const animatedValue = React.useRef(new Animated.Value(0.5)).current;
    const animatedStyle = {
    transform: [{ scale: animatedValue }]
    }

    function resetAnimations(){
        fadeAnim.setValue(0);
        animatedValue.setValue(0.5);
        Animated.timing(fadeAnim, {
            toValue: 1,
            useNativeDriver:true,
            duration: 1000
        }).start();
        setTimeout(() => {
            Animated.spring(animatedValue, {
                toValue: 1,
                friction: 3,
                tension: 20
            }).start();
        }, 200);
    }

    React.useEffect(() => {
        resetAnimations();
        async function getData(){
            const prov = await careteam.getAllProviders();
            // setProvider(prov[0].name);
        }
        getData();
    }, []);

    const swiperRef = React.createRef();
    const navigation = useNavigation();

    const completeWalkthrough = async () => {
        try{
            await AsyncStorage.setItem('WALKTHROUGH', 'true');
            console.log('Walkthrough completed');
        }
        catch(e){
            console.log(e);
        }
    }

    return(
        <Container>
            <Header gradient />
            <Swiper ref={swiperRef} paginationStyle={styles.pagination} showsButtons={false} loop={false} autoplay={false} index={0} style={styles.swiper} activeDot={<ActiveDot/>}>
                
                <FirstTimeScreenItem style={{opacity: fadeAnim}} data={{title: <Title>MY REPORT</Title>, subtitle: <Text>The My Report section of the Symptom Care at Home app is where you will access <Bold>the interactions within SCH that you are being asked to complete.</Bold> Each time there are questions for you to answer, a number will show on the home screen to show you that it is time to complete your SCH interaction.  Be sure to check this area often so you don’t miss answering questions.</Text>, image: <ContentImage style={animatedStyle} source={require('../../assets/images/Interactions.png')}/>}}>
                    <Button onPress={() => {swiperRef.current.scrollBy(1); resetAnimations()}} text='NEXT' outline></Button>
                </FirstTimeScreenItem>

                <FirstTimeScreenItem style={{opacity: fadeAnim}} data={{title: <Title>FEEDBACK</Title>, subtitle: <Text>The Feedback section of the app is where you will find direct messages from providers or staff and information your care/research team may send you.  If you receive feedback during your SCH interaction, you can look back at previous information on this screen as well. Not all interactions provide feedback.</Text>, image: <ContentImage style={animatedStyle} source={require('../../assets/images/feedback2.png')}/>}}>
                    <Button onPress={() => {swiperRef.current.scrollBy(1); resetAnimations()}} text='NEXT' outline></Button>
                </FirstTimeScreenItem>

                <FirstTimeScreenItem style={{opacity: fadeAnim}} data={{title: <Title>CHECK IN</Title>, subtitle: <Text>Sometimes your care/research team may want to know something like your temperature or request uploading a photo of something being monitored.  This section of the app is only active when your team requests something from you so you may not be  asked to use this part of the app.</Text>, image: <ContentImage style={animatedStyle} source={require('../../assets/images/checkin.png')}/>}}>
                    <Button onPress={() => {swiperRef.current.scrollBy(1); resetAnimations()}} text='NEXT' outline></Button>
                </FirstTimeScreenItem>

                <FirstTimeScreenItem style={{opacity: fadeAnim}} data={{title: <Title>LIBRARY</Title>, image: <ContentImage style={animatedStyle} source={require('../../assets/images/library.png')}/>}}>
                    <Text>There may be information that your care or research team will want you to have as a reference.  The Library is where you will find this information.  Be sure to check in on this section occasionally as new content may be  added at any time.</Text>
                    <Button onPress={() => {swiperRef.current.scrollBy(1); resetAnimations()}} text='NEXT' outline></Button>
                </FirstTimeScreenItem>

                <FirstTimeScreenItem style={{opacity: fadeAnim}} data={{title: <Title>MORE</Title>, subtitle: `When you click on the three “…” in the bottom menu of the SCH app, you will be able to access your account information, information about your care/research team, or sign out of the SCH application.`, image: <ContentImage style={animatedStyle} source={require('../../assets/images/more.png')}/>}}>
                    <Button onPress={() => {navigation.navigate('Home'); completeWalkthrough()}} text='GET STARTED'></Button>
                </FirstTimeScreenItem>
            </Swiper>
        </Container>
    )
}