import React from 'react';
import { Text } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import styles, { Button, ButtonText } from './styles';

export default function FooterButton(props){

    const [active, setActive] = React.useState(false);

    React.useEffect(()=>{
        setActive(props.checked)
    }, [props.checked])

    function action(){
        setActive(!active)
        props.onPress();
    }

    return(
        <Button onPress={action}>
            <Text>
                <AntDesign style={styles.btnIcon} name={active ? props.active : props.inactive}/>
            </Text>
            <ButtonText>{props.title}</ButtonText>
        </Button>
    )
}