import styled from 'styled-components/native';
import { ScrollView } from 'react-native';
import Colors from '../../constants/colors';
import { AntDesign } from '@expo/vector-icons';

export const Container = styled(ScrollView)`
    flex: 1;
`
export const TextHeader = styled.View`
    height: 190px;
    width: 100%;
    display: flex;
`
export const HeaderBg = styled.View`
    height: 100%;
    width: 100%;
    background-color: #019FBA;
`
export const Logo = styled.Image`
    width: 80px;
    height: 80px;
    border-radius: 10px;
    position: absolute;
    margin-top: 30px;
    align-self: center;
    resize-mode: contain;
`
export const ScreenTitle = styled.Text`
    font-size: 24px;
    color: #fff;
    position: absolute;
    align-self: center;
    margin-top: 120px;
`
export const TeamTitle = styled.Text`
    color: ${Colors.primaryColor};
    font-weight: bold;
    padding-vertical: 10px;
    margin-horizontal: 10px;
    border-bottom-width: 1px;
    border-bottom-color: ${Colors.tabIconDefault};
`
export const Spacing = styled.View`
    height: 20px;
`
export const BackButton = styled.TouchableOpacity`
    position: absolute;
    margin-top: 70px;
    margin-left: 25px;
`