import styled from 'styled-components/native';

export const Container = styled.View`
    flex: 1;
    align-items: center;
    padding: 20px;
    justify-content: space-around;
`;

export const Label = styled.Text`
    font-size: 20px;
`;

export const Spacing = styled.View`
    height: 20px;
`;