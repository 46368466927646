import React from "react";

import { 
  ScrollView, 
  TouchableOpacity,
  Alert, } from 'react-native';

import { AntDesign } from '@expo/vector-icons';
import { useNavigation, NavigationNativeContainer } from '@react-navigation/native';

import styles, 
      {  Container, 
          Datetime,
          TitleHeader, 
          Textarea, 
          TextBottom, 
          TextHint,
          ContainerButton, 
          Subject} from './styles';

import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Colors from '../../../constants/colors';
import checkin from '../../../api/checkin';

import moment from 'moment';
import Spinner from 'react-native-loading-spinner-overlay';

export default function AddNoteScreen({route}){

  const navigation = useNavigation();

  const [subject, setSubject] = React.useState('');
  const [content, setContent] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  function back(){
    return(
        <TouchableOpacity onPress={() => {
          if(subject !== '' && content !== ''){
            Alert.alert(`It looks like you've entered text, would you like to delete this note?`, null, [
              {
                text: 'Yes',
                onPress: () => navigation.goBack()
              },
              {
                text: 'No',
              }
            ])
          }
          else{
            navigation.goBack();
          }
          }}>
            <AntDesign name='leftcircleo' size={40} color={Colors.primaryColor}/>
        </TouchableOpacity>
    );
  }
  
  async function submitNote(){
    if(subject !== '' && content !== ''){
      setLoading(true);
      const response = await checkin.submitNote(subject, content);
      if(response.status === 200){
        Alert.alert('Success!');
      }
      else{
        Alert.alert('Some error occurred!');
      }
      navigation.goBack();
      setLoading(false);
    }
    else{
      Alert.alert(`Please fill the "Subject" and text field!`);
    }
  }

  const date = moment()
      .format('MMMM D, YYYY');

  return(
    
    <ScrollView keyboardShouldPersistTaps='handled'> 
      <Container>
        <Header solidBar leftButton={back}/>
        <Datetime> {date} </Datetime>
        <TitleHeader>Leave note for Provider</TitleHeader>
        <Subject onChangeText={(text) => setSubject(text)} placeholder='Subject' />
        <Textarea onChangeText={(text) => setContent(text)} multiline placeholder="Here is where I can enter my note to my proveders." />
        <TextBottom> Tap Above To Type </TextBottom>
        <TextHint> If this is a medical emergency, please call 911 immediately. </TextHint>
        <ContainerButton>
          <Button onPress={submitNote} width="100%" text="Submit Entry" color='#0053a1' textColor='#fff' />
        </ContainerButton>
      </Container>
      <Spinner
            visible={loading}
            textContent={'Loading...'}
            overlayColor='rgba(0, 83, 159,0.9)'
            color='#fff'
            textStyle={{color: '#fff'}}
        /> 
    </ScrollView>

  )
}
