import Colors from '../../constants/colors';
import React from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';

export default StyleSheet.create({ 
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    elevation: 6,
  }
})
export const Box = styled.View`
    height: 70px;
    margin-top: 40px;
`
export const BoxShadow = styled.View`
    background-color: #fff;
    height: 65px;
    width: 140px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
`

export const Title = styled.Text`
    font-size: 11px;
    margin-left: 20px;
    margin-top: 5px;
    color: ${Colors.primaryColor};
    font-weight: bold;
`
export const Name = styled.Text`
    font-size: 11px;
    margin-left: 20px;
    margin-top: 5px;
    font-weight: 600;
`
export const Date = styled.Text`
    font-size: 10px;
    margin-left: 20px;
`