export const medium = {
    fontWeight: '600'
};

export const strong = {
    fontWeight: '700'
};

export const light = {
    fontWeight: '300'
};

export const uppercase = {
    textTransform: 'uppercase'
};