import React from 'react';
import styled from 'styled-components/native';

export const Container = styled.View`
    background-color: #F2F4F6;
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-content: center;
  `
export const AnswerContainer = styled.ScrollView`
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-content: center;
  `

export const ProgressBarContainer = styled.View`
    backgroundColor: #EDEEF1;
    height: 70px;
    width: 100%;
    padding: 10px 50px;
  `