import React, { useState, useRef } from "react";
import { MaterialCommunityIcons, FontAwesome, Feather, Foundation } from '@expo/vector-icons';
import { StyleSheet, Text, View, Animated } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { LinearGradient } from 'expo-linear-gradient';
import Toaster from 'react-native-root-toast';
import Colors from '../../constants/colors';
import Dictionary from '../../constants/dictionary';
import { Logo } from '../../components/Logo';
import CircledButton from '../../components/CircledButton';
import checkin from '../../api/checkin';

import ModalBloodPressure from './ModalBloodPressure';
import ModalNumber from './ModalNumber';
import ModalVoice from './ModalVoice';

import Popup from '../../components/Modal';

import { useNavigation } from '@react-navigation/native';
import { Dimensions } from "react-native";


export default function CheckinScreen() {

  const navigation = useNavigation();

  const [toast, setToast] = useState(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalHoursOfSleepVisible, setModalHoursOfSleepVisible] = useState(false);
  const [modalModalBloodPressure, setModalBloodPressure] = useState(false);
  const [modalNumberVisible, setModalNumberVisible] = useState(false);
  const [modalVoiceVisible, setModalVoiceVisible] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  // number modal infos
  const [numberModalName, setNumberModalName] = useState('');
  const [numberModalUnit, setNumberModalUnit] = useState('');

  // voice modal infos
  const [voiceModalName, setModalVoiceName] = useState('');

  const [buttons, setButtons] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [requireds, setRequireds] = useState(0);
  const [collectorId, setCollectorId] = useState(null);

  const fadeAnim = useRef(new Animated.Value(0)).current;

  const mapping = [
    {
      type: 'BloodPressure',
      icon: <FontAwesome name="heartbeat" color={Colors.primaryColor} size={36} />,
    },
    {
      type: 'Occurrence',
      icon: <Foundation name="page-edit" color={Colors.primaryColor} size={36} />,
    },
    {
      type: 'Number',
      icon: <Foundation name="page-edit" color={Colors.primaryColor} size={36} />,
    },
    {
      type: 'Text',
      icon: <Foundation name="page-edit" color={Colors.primaryColor} size={36} />,
    },
    {
      type: 'VoiceNote',
      icon: <Foundation name="page-edit" color={Colors.primaryColor} size={36} />,
    },
    {
      type: 'Image',
      icon: <Feather name="camera" color="#0053A1" size={36} />,
    },
    {
      type: 'Medication',
      icon: <MaterialCommunityIcons name="pill" color={Colors.primaryColor} size={36} />,
    },
  ]

  setTimeout(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      useNativeDriver: true,
      duration: 500
    }).start();
  }, 500);


  React.useEffect(() => {
    async function getData() {
      const btn = await checkin.getCheckin();
      setButtons(btn);
      setLoadingData(false);
    }
    getData();
  }, [])

  function toggleModal() {
    setModalVisible(!modalVisible);
  }
  function toggleModalHoursOfSleep() {
    setModalHoursOfSleepVisible(!modalHoursOfSleepVisible);
  }
  function toggleModalBloodPressure() {
    setModalBloodPressure(!modalModalBloodPressure);
  }
  function toggleModalNumber() {
    setModalNumberVisible(!modalNumberVisible);
  }
  function toggleModalVoice() {
    setModalVoiceVisible(!modalVoiceVisible);
  }

  // this function gets the corresponding action
  function getAction(button) {
    console.log(`Get the ${button.type} action`)
    switch (button.type) {
      case 'BloodPressure':
        setCollectorId(button.metricCollectorId);
        toggleModalBloodPressure();
        break;

      case 'Occurrence':
        navigation.navigate('AddNote', { metricCollectorId: button.metricCollectorId });
        break;

      case 'Number':
        setNumberModalName(button.name);
        setCollectorId(button.metricCollectorId);
        if (button.metadata) {
          setNumberModalUnit(button.metadata.unit);
        }
        toggleModalNumber();
        break;

      case 'Text':
        navigation.navigate('AddNote', { metricCollectorId: button.metricCollectorId });
        break;

      case 'VoiceNote':
        setModalVoiceName(button.name);
        setCollectorId(button.metricCollectorId);
        toggleModalVoice();
        break;

      case 'Image':
        navigation.navigate('AddPictureScreen', { metricCollectorId: button.metricCollectorId });
        break;

      case 'Medication':
        navigation.navigate('AddMedications', { metricCollectorId: button.metricCollectorId });
        break;
    }
  }

  function showToast(type, msg) {
    setToast({
      text: msg, styles:
      {
        container: {
          marginTop: 50,
          backgroundColor: type === 'success' ? '#1ddca9' : '#ff4073',
          width: 250,
          padding: 10,
          borderRadius: 100,
          alignSelf: 'center',
        },
        text: {
          color: '#fff',
          textAlign: 'center',
          fontWeight: 'bold',
        }
      }, duration: 1000
    }
    );
    setToast(null)
  }

  return (
    <View style={styles.container}>
      <LinearGradient colors={[Colors.secondaryColor, Colors.primaryColor]}
        style={{ height: '100%' }}>
        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer} keyboardShouldPersistTaps='handled'>
          <View style={styles.header}>
            <Logo style={styles.logo} size={{ width: 88, height: 79 }}></Logo>
            {requireds !== 0 && <Text style={styles.titleHeader}>Required Check Ins</Text>}
          </View>
          {requireds !== 0 && <View style={styles.steps}>
            <Text style={styles.stepsText}>{requireds === 0 ? 'No Required Checkins' : `${completed} of ${requireds} Completed`}</Text>
          </View>}
          <Animated.View style={{ opacity: fadeAnim }}>
            <View style={styles.circles}>
              {
                buttons.map((b, i) => {
                  if (b.required) {
                    setRequireds(requireds + 1);

                    // get the button icon
                    let mapped = mapping.filter(btn => {
                      return b.type === btn.type;
                    })

                    return (
                      <CircledButton
                        key={i}
                        icon={mapped[0].icon}
                        title={`${b.name}`}
                        hasBorder={true}
                        background={Colors.primaryColor}
                        textColor={Colors.noticeText}
                        textSize={10}
                        size={Dictionary.SIZE_LARGE}
                        Action={() => getAction(b)}
                        completed={b.required}
                      />
                    )
                  }
                  if (b.completed) {
                    setCompleted(completed + 1);
                  }
                })
              }

            </View>
            <View style={styles.containerAvailableCheckIns}>
              <Text style={styles.availableCheckText}> Available Check Ins </Text>
              <View style={styles.containerCirclesAvailable}>
                {
                  buttons.map((b, i) => {

                    // get the button icon
                    let mapped = mapping.filter(btn => {
                      return b.type === btn.type;
                    })

                    return (
                      <CircledButton
                        key={i}
                        icon={mapped[0].icon}
                        title={b.name}
                        background={Colors.noticeText}
                        textColor={Colors.primaryColor}
                        textSize={8}
                        size={Dictionary.SIZE_MEDIUM}
                        Action={() => getAction(b)}
                        completed={b.required}
                      />
                    )
                  })
                }
                {modalModalBloodPressure &&
                  <Popup
                    titleModal="Blood Pressure"
                    contentModal={<ModalBloodPressure toast={showToast} metricCollectorId={collectorId} return={toggleModalBloodPressure} />}
                    onClose={() => toggleModalBloodPressure()}
                    stateModal={modalModalBloodPressure} />
                }
                {modalNumberVisible &&
                  <Popup
                    titleModal={numberModalName}
                    contentModal={<ModalNumber toast={showToast} metricCollectorId={collectorId} unit={numberModalUnit} return={toggleModalNumber} />}
                    onClose={() => toggleModalNumber()}
                    stateModal={modalNumberVisible} />
                }
                {modalVoiceVisible &&
                  <Popup
                    titleModal={voiceModalName}
                    contentModal={<ModalVoice toast={showToast} metricCollectorId={collectorId} return={toggleModalVoice} />}
                    onClose={() => toggleModalVoice()}
                    stateModal={modalVoiceVisible} />
                }
              </View>
            </View>
          </Animated.View>
        </ScrollView>
      </LinearGradient>
      {toast &&
        <Toaster>{Toaster.show(toast, {
          duration: Toaster.durations.LONG,
          animation: true,
          hideOnPress: true,
        })}</Toaster>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  sizeNormal: {
    width: 128,
    height: 128,
  },
  container: {
    flex: 1,
  },
  contentContainer: {
    paddingTop: 35,
  },
  header: {
    flex: 1,
    alignItems: 'center',
    paddingBottom: 20,
    marginTop: 15,

  },
  logo: {
    marginBottom: 20,
    height: 110,
    width: 110,
  },
  titleHeader: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingTop: 20,
    // fontFamily: 'SFProTextLight',
    fontWeight: '100',
    lineHeight: 29,
  },
  body: {
    flex: 1,
    margin: 20,
  },
  steps: {
    flex: 1,
    alignItems: 'center',
    paddingBottom: 15,
    paddingTop: 20,
  },
  stepsText: {
    color: '#000',
    fontWeight: 'bold',
    // fontFamily: Dictionary.FONT_BOLD,
    fontSize: 14,
    lineHeight: 16,
  },
  circles: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignSelf: 'center',
    maxWidth: 500,
  },
  containerAvailableCheckIns: {
    marginTop: 20,
    marginBottom: 50,
    paddingBottom: 10,
    backgroundColor: 'rgba(255,255,255,0.25)',
  },
  availableCheckText: {
    flex: 1,
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    color: Colors.noticeText,
    fontSize: 24,
  },
  containerCirclesAvailable: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: 500,
    alignSelf: 'center',
    height: '100%',
  },
});
