import React, { useState } from 'react';
import { View } from 'react-native';
import Colors from '../../constants/colors';
import NumberInput from '../NumberInput';
import Slider from 'react-native-slider';

import style, { InputContainer, BarInputContainer, BarAndNumbers, ScaleNumber, LeftText, RightText, LabelsContainer } from './styles';

export default function NumberBarInput(props) {
  const half = props.metadata.upperValue/2;
  const [numberInput, setNumberInput] = useState(half);

  const upperValueNumberLabel = String(props.metadata.upperValue);
  const lowerValueNumberLabel = String(props.metadata.lowerValue);

  React.useEffect(() => {
    props.response(numberInput);
  })
  
  function handleNumberInput(value){
    setNumberInput(Number(value));
    props.response(numberInput);
  }


  return(
    <InputContainer>
      <NumberInput
        {...props}
        max={props.max} min={props.min} 
        onChangeText={handleNumberInput}
        value={numberInput}
      />
      <BarInputContainer>
        <BarAndNumbers>
          <ScaleNumber>{lowerValueNumberLabel}</ScaleNumber>
          <Slider
            minimumValue={props.metadata.lowerValue}
            maximumValue={props.metadata.upperValue}
            minimumTrackTintColor={Colors.crimson}
            maximumTrackTintColor='white'
            thumbTintColor='white'
            thumbStyle={style.thumb}
            trackStyle={style.track}
            value={numberInput}
            step={1}
            onValueChange={(value) => handleNumberInput(value)}
          />
          <ScaleNumber>{upperValueNumberLabel}</ScaleNumber>
        </BarAndNumbers>
        <LabelsContainer>
          <LeftText>{props.metadata.lowerLabel}</LeftText>
          <RightText>{props.metadata.upperLabel}</RightText>
        </LabelsContainer>
      </BarInputContainer>
    </InputContainer>
  )
}

