import React from  'react';

import { createStackNavigator, TransitionSpecs } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';

import LibraryTopics from './screens/LibraryScreen/LibraryTopics';
import ArticleDetails from './screens/LibraryScreen/ArticleDetails';
import TopicArticles from './screens/LibraryScreen/TopicArticles';
import ItemFeedback from './screens/ItemFeedback/';
import FirstTimeScreen from './screens/FirstTimeScreen';

import BottomTabNavigator from './navigation/BottomTabNavigator';
import DailyInteractionScreen from './screens/DailyInteractionScreen';

import CheckinScreen from './screens/CheckinScreen';
import AddNoteScreen from './screens/CheckinScreen/AddNoteScreen';

import LibraryScreen from './screens/LibraryScreen';
import LoginScreen from './screens/LoginScreen'
import CaregiverDetail from './screens/CaregiverDetail';
import ProviderDetail from './screens/ProviderDetail';
import MyCareTeam from './screens/MyCareTeam';
import AddMedications from './screens/AddMedications';
import InteractionSummary from './screens/ItemFeedback/InteractionSummary';
import AddPictureScreen from './screens/CheckinScreen/AddPictureScreen';
import ReportsScreen from './screens/ReportsScreen';


const Stack = createStackNavigator();


export default function Routes() {

    const transitionConfig = {
      transitionSpec: {
        open: {
          animation: 'timing',
          config: {
            stiffness: 1000,
            damping: 500,
          },
        },
        close: {
          animation: 'timing',
          config: {
            stiffness: 1000,
            damping: 500,
          },
        }
      }};

    const containerRef = React.useRef();

    return (
      <NavigationContainer ref={containerRef}>
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Root"  component={LoginScreen} options={transitionConfig}/>
          <Stack.Screen name="Login" component={LoginScreen} options={transitionConfig} />
          <Stack.Screen name="Home" component={BottomTabNavigator} options={transitionConfig} />
          <Stack.Screen name="DailyInteraction" component={DailyInteractionScreen}/>
          <Stack.Screen name="Checkin" component={CheckinScreen} options={transitionConfig}/>
          <Stack.Screen name="AddNote" component={AddNoteScreen} options={transitionConfig}/>
          <Stack.Screen name="Library"  component={LibraryScreen} options={transitionConfig}/>
          <Stack.Screen name="LibraryTopics"  component={LibraryTopics} options={transitionConfig}/>
          <Stack.Screen name="ArticleDetails"  component={ArticleDetails} options={transitionConfig}/>
          <Stack.Screen name="ItemFeedback"  component={ItemFeedback} options={transitionConfig}/>
          <Stack.Screen name="CaregiverDetail"  component={CaregiverDetail} options={transitionConfig}/>
          <Stack.Screen name="FirstTimeScreen"  component={FirstTimeScreen} options={transitionConfig}/>
          <Stack.Screen name="ProviderDetail"  component={ProviderDetail} options={transitionConfig}/>
          <Stack.Screen name="MyCareTeam"  component={MyCareTeam} options={transitionConfig}/>
          <Stack.Screen name="AddMedications"  component={AddMedications} options={transitionConfig}/>
          <Stack.Screen name="InteractionSummary"  component={InteractionSummary} options={transitionConfig}/>
          <Stack.Screen name="AddPictureScreen"  component={AddPictureScreen} options={transitionConfig}/>
          <Stack.Screen name="TopicArticles"  component={TopicArticles} options={transitionConfig}/>
          <Stack.Screen name="Reports"  component={ReportsScreen} options={transitionConfig}/>
        </Stack.Navigator>
      </NavigationContainer>
    );
}