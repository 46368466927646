import styled from 'styled-components/native';

export const Container = styled.View`
    flex: 1;
    padding: 20px;
    align-items: center;
`

export const Label = styled.Text`
    font-size: 20px;
    text-align: center;
`

export const Bold = styled.Text`
    font-size: 20px;
    font-weight: bold;
`

export const Warning = styled.Text`
    font-size: 16px;
    color: red;
    text-align: center;
    font-weight: bold;
`

export const Spacing = styled.View`
    height: 20px;
`